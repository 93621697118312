import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {UpdateUser} from "../View/UpdateDate";
import {Configuration} from "../View/Configuration";
import {MyProfile} from "../View/MyProfile";

export const RoutesProfile = () => {
  return (
    <Routes>
        <Route path='settings' element={<Configuration />} />
        <Route path='view' element={<MyProfile />} />
        <Route path='update' element={<UpdateUser />} />

    
    </Routes>
  )
}
