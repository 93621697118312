import {useDataCollectionRequest} from "../Hooks";

export const SelectJuzgados = ({ onChange = () => { }, defaultValue = '', name = '', id_estado = 0, id_ciudad = 0, id_materia = 0, disabled = false }) => {

        const { dataCollectionRequest: getJuzgados } = useDataCollectionRequest(
            `obtener_juzgados_por_estado_ciudad_municipio_material&id_estado=${parseInt(id_estado)}&id_ciudad_municipio=${parseInt(id_ciudad)}&id_materia=${parseInt(id_materia)}`,
            'all'
        );

        console.log(`obtener_juzgados_por_estado_ciudad_municipio_material&id_estado=${parseInt(id_estado)}&id_ciudad_municipio=${parseInt(id_ciudad)}&id_materia=${parseInt(id_materia)}`);

        return (
            <select
                className='form-select'
                name={name}
                disabled={disabled}
                onChange={(e) => {
                    onChange(e);
                }}
            >
                <option value={''}>Selecciona un juzgado</option>
                {
                    getJuzgados &&
                    getJuzgados?.map((juzgado) => (
                        <option key={juzgado.id} value={juzgado.id} selected={juzgado.id == defaultValue} >{juzgado.nombre}</option>
                    ))
                }
            </select>
        )
}