import React from 'react'

export const AudienceAlert = () => {
    
    
    return (
        <div className="alert alert-secondary" role="alert">
            Alerta de que hay audiencia
        </div>
    )
}
