import { useEffect, useState } from "react";
import { baseUrl, baseUrlServices } from "../shared/";

export const useRequestGet = ( serviceName, refresh = false ) => {
    
    const [data, setData] = useState({
        isLoading: false,
        isError: false,
        data: null
    });

    useEffect(() => {
        setData({
            isLoading: true,
            isError: false,
            data: null
        });  

        fetch( baseUrlServices.concat(serviceName) )
            .then( response => response.text() )
            .then( data => {
                setData({
                    isLoading: false,
                    error: false,
                    data: data
                });
            })
            .catch( error => {
                console.log(error);
            })

    },[serviceName, refresh]);
    

    return data
}
