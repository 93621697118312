import {useState, useEffect} from "react";
import {useRequestGet} from "./useRequestGet";

export function useDataCollectionRequest ( url, method, refresh = false ) {

    const {data} = useRequestGet(url, refresh);

    const [dataCollectionRequest, setDataCollectionRequest] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

            try {

                if (data) {
                    if (method === 'row') {
                        setDataCollectionRequest(JSON.parse(data)[0]);

                    } else {
                        setDataCollectionRequest(JSON.parse(data));
                    }
                }

            } catch (error) {
                setError(error);
                console.log("useDataCollectionRequest: ", error);
            }

    },[data, method]);


    return {
        dataCollectionRequest,
        setDataCollectionRequest,
        error
    };
}