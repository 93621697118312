import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../Context';
import { useForm } from '../../Hooks';
import logo  from '../../Assets/img/Abogado_azuLOsLetra2a.png'
import {requestPost} from "../../Helpers";
import {alertError} from "../../Helpers/Alerts";

export const LoginPage = () => {
  
  const {login} = useContext(AuthContext);

  const navigate = useNavigate()

  const {user, password, onInputChange} = useForm();


  const onLogin = () => {

    const formData = new FormData();
    formData.append('email', user);
    formData.append('password', password);

    requestPost('autenticar_usuario', formData)
        .then((resp) => {
          resp = JSON.parse(resp);
          if (Array.isArray(resp)) {
            const {id, nombre, apellidos, num_celular, rol, id_rol} = resp[0];
            login(user, password, id, nombre, apellidos, num_celular, rol, id_rol);
            navigate('/dashboard', { replace: true });
          } else {
            alertError('Usuario o contraseña incorrectos', 'Por favor, verifique sus credenciales')
          }
        } )


  }
  
  return (
    <div className='row vw-100'>

      <h1 className='text-center mb-4 mt-4'> Ingresar a mi sesión </h1>

      <div className='row vw-100'>

        <div className='col-12 col-md-6'>
          <img src={logo} alt="" className="img-fluid p-5" />
        </div>
        
        <div className='col-12 col-md-6'>
          
          <div className='d-flex flex-column justify-content-center w-75 m-auto '>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" name='user' onChange={onInputChange}/>
              <label for="floatingInput"> Correo electronico </label>
            </div>

            <div class="form-floating">
              <input type="password" class="form-control" id="floatingPassword" placeholder="Password" name='password' onChange={onInputChange}/>
              <label for="floatingPassword"> Contraseña </label>
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <button
              onClick={onLogin}
              className="btn btn-primary w-25 mt-3"
            >
              Ingresar
            </button>
          </div>

        </div>
      
      </div>

    </div>
  )
}
