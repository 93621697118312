import React from 'react'

export const CardHorizontal = ({img='', cardTitle, cardBody, cardParagraph}) => {
  return (
    <div className="card mb-3" style={{"maxWidth": "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src={img} className="img-fluid rounded-start p-3" alt={cardTitle} />
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title"> {cardTitle} </h5>
        <div className="card-text"> {cardBody} </div>
        <p className="card-text"><small className="text-muted"> {cardParagraph} </small></p>
      </div>
    </div>
  </div>
</div>
  )
}
