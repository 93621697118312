

import React, {useState, useCallback, useMemo, useRef} from 'react'
import { useDataCollectionRequest, useForm } from '../../Hooks';
import { AgGridReact } from 'ag-grid-react';
import { SearchDataToTable } from '../../Components/SearchDataToTable';
import { requestPost } from '../../Helpers';
import { alertDialog, alertToast } from '../../Helpers/Alerts';
import {BsTrash} from "react-icons/bs";
import {HiViewGridAdd} from "react-icons/hi";

  
export const RegisterMaterias = () => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getMatter, setDataCollectionRequest : setMatter } = useDataCollectionRequest(
        'obtener_materias',
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterMatter = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const addMatter = (data) => {

        const formData = new FormData();
        formData.append('materia_info', JSON.stringify(data));

        requestPost('agregar_materia', formData)
            .then((resp) => {
                if (resp > 0) {
                    // asignar el id al objeto que se agrego a la tabla para poder actualizarlo despues de agregarlo a la base de datos
                    data.id = resp;
                    // show a toast to alert the user that the matter was added
                    alertToast('La Materia fue agregada correctamente', 'success');
                    return resp;
                } else {
                    alertToast('No se pudo agregar la materia', 'error');
                    return null;
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const updateMatter = (data) => {

        const formData = new FormData();
        formData.append('materia_info', JSON.stringify(data));

        requestPost('actualizar_materia', formData)
            .then((resp) => {
                console.log(resp);
                if (resp == '1' || resp == 1) {
                    alertToast('Materia actualizada correctamente', 'success');
                } else {
                    alertToast('No se pudo actualizar la materia', 'error');
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const deleteMatter = (id_matter) => {

        console.log(id_matter);

        const formData = new FormData();
        formData.append('materia_id', id_matter);

        requestPost('eliminar_materia', formData)
            .then((resp) => {
                console.log(resp);
                if (resp == '1' || resp == 1) {
                    setDataUpdate(!dataUpdate)
                    alertToast('Materia eliminada correctamente', 'success');
                } else {
                    alertToast('No se pudo eliminar la materia', 'error');
                }
                
            })
            .catch((err) => {
                
            })
    }
    

    // add a row to table
    const addItems = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                tipo_juicio: `Materia ${getRowCount + 1}`,
                abreviatura: '',
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);
    
    const onCellValueChanged = useCallback((e) => {

        const { data } = e;

        if (data?.id === null) {
            addMatter(data);
        } else {
            updateMatter(data);
        }


    }, []);

  return (
    <div className='' style={containerStyle}>
    
        <h3 className='m-3 mb-4 text-center'> Registrar Materias </h3>

        <form className='d-flex flex-column row'>

            <div className={'w-75 m-auto'}>
                <SearchDataToTable name={'Buscar Materia: '} onFilter={onFilterMatter} />
            </div>

            <div className='d-flex justify-content-end '>
                <button 
                    className='btn btn-primary w-25 me-4'
                    onClick={ (e) => {
                        e.preventDefault();
                        addItems();
                    } }
                >
                    <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Materia
                </button>
            </div>

        </form>

        <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
            <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                columnDefs={[
                    { field: 'id', headerName: 'ID', hide: true, },
                    { field: 'tipo_juicio', headerName: 'Materia' },
                    { field: 'abreviatura', headerName: 'Abreviatura' },
                    {
                        field: 'button',
                        headerName: 'Eliminar',
                        editable: false,
                        cellRenderer: (params) => {
                            const { id } = params.data;
                            
                            return (
                                <div className='d-flex justify-content-evenly'>
                                    <button
                                        className='btn btn-danger'
                                        onClick={() => {
                                            alertDialog('¿Estás seguro de eliminar la materia?', 'Eliminar Materia', 'Cancelar')
                                                .then((resp) => {
                                                    if (resp) {
                                                        deleteMatter(id);
                                                    }
                                                })
                                        }}
                                    >
                                        <BsTrash className={'fs-5 m-auto mx-1'} /> Eliminar
                                    </button>
                                </div>
                            );
                        },
                    },
                ]}
                rowData={getMatter}
                rowSelection='single'
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                pagination={true}
                paginationPageSize={13}
            />

        </div>

        <div className='m-3'>

        </div>

</div>
  )
}
