import {juzgadoOsala} from "./helpers/juzgadoOsala";
import {useDataCollectionRequest} from "../../../Hooks";
import {juezOmagistrado} from "./helpers/juezOmagistrado";
import {SelectEstados} from "../../../Components/SelectEstados";
import {SelectCiudades} from "../../../Components/SelectCiudades";
import {SelectJuzgados} from "../../../Components/SelectJuzgados";
import {SelectTipoJuicio} from "../../../Components/SelectTipoJuicio";
import {SelectAccion} from "../../../Components/SelectAccion";

const valuesByDefault = {
    cityState: '',
    municipality: '',
    poblado: '',
    court: '',
    nameJudge: '',
    nombre_secretario_del_juez: '',
    typeOfTrial: '',
    accion: '',
    expediente_origen: '',
    juzgado_origen: '',
}

export const Catalogo = ({onInputChange, formState, chosen_matter, id_matter, defaultValues = valuesByDefault}) => {


    return (
        <div className="row mt-2">

            <h3 className="col-12 text-center text-black-50 mb-2">Catálogo:</h3>

            <div className="row">
                <div className="mb-3 col-12 col-md-6">
                    <div className='input-group mb-3'>
                        <label htmlFor="stateSelect" className="input-group-text"> Estado: </label>
                        <SelectEstados
                            name={'cityState'}
                            onChange={onInputChange}
                            defaultValue={defaultValues?.cityState}
                        />
                    </div>
                </div>

                <div className="mb-3 col-12 col-md-6">
                    <div className='input-group mb-3' >
                        <label htmlFor="municipalitySelect" className="input-group-text">Municipio:</label>
                        <SelectCiudades
                            id_estado={formState.cityState}
                            onChange={onInputChange}
                            name={'municipality'}
                            disabled={formState?.cityState > 0 ? false : true}
                            defaultValue={defaultValues?.municipality}
                        />
                    </div>
                </div>

            </div>

            <div className="row">

                {
                    chosen_matter === 'Agrario' && (
                        <div className="mb-3 col-12 col-md-6">
                            <div className="input-group mb-3">
                                <label htmlFor="poblado" className="input-group-text">Poblado o ejido:</label>
                                <input
                                    type={'text'}
                                    className="form-control"
                                    id="poblado"
                                    name="poblado"
                                    placeholder={'Nombre del poblado o ejido'}
                                    defaultValue={defaultValues?.poblado}
                                />
                            </div>
                        </div>
                    )
                }

                <div className="mb-3 col-12 col-md-6">
                    <div className="input-group mb-3">
                        <label htmlFor="judgesSelect" className="input-group-text"> {juzgadoOsala(chosen_matter)}: </label>
                        <SelectJuzgados
                            id_estado={formState.cityState}
                            id_ciudad={formState.municipality}
                            id_materia={id_matter}
                            onChange={onInputChange}
                            name={'court'}
                            disabled={parseInt(id_matter) && formState?.municipality > 0 ? false : true}
                            defaultValue={defaultValues?.court}
                        />
                    </div>
                </div>

            </div>

            <div className="row">

                <div className='mb-3 col-12 col-md-6'>
                    <div className='input-group mb-3'>
                        <label htmlFor='nameJudge' className='input-group-text'>
                            Nombre del {juezOmagistrado(chosen_matter)}:
                        </label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder={'Nombre del ' + juezOmagistrado(chosen_matter)}
                            name='nameJudge'
                            id='nameJudge'
                            defaultValue={defaultValues?.nameJudge}
                        />
                    </div>
                </div>

                <div className='mb-3 col-12 col-md-6'>
                    <div className='input-group mb-3'>
                        <label htmlFor='name' className='input-group-text'> Nombre del Secretario: </label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Nombre del Secretario'
                            id=''
                            name='nombre_secretario_del_juez'
                            defaultValue={defaultValues?.nombre_secretario_del_juez}
                        />
                    </div>
                </div>

            </div>

            <div className={'row'}>
                <div className="mb-3">
                    <div className="input-group mb-3">
                        <label htmlFor="matterSelect" className="input-group-text">Materia:</label>
                        <input className="form-control" id="matterSelect" name="matter" value={chosen_matter} disabled />
                    </div>
                </div>
            </div>


            <div className="row">

                {
                    chosen_matter !== 'Amparo' ? (
                        <>
                            <div className="mb-3 col-12 col-md-6">

                                <div className={'input-group mb-3'}>
                                    <label htmlFor="tipoJuicio" className="input-group-text">Tipo de Juicio:</label>
                                    <SelectTipoJuicio
                                        id_materia={id_matter}
                                        onChange={onInputChange}
                                        name={'typeOfTrial'}
                                        disabled={parseInt(id_matter) > 0 ? false : true}
                                        defaultValue={defaultValues?.typeOfTrial}
                                    />
                                </div>

                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <div className={'input-group mb-3'}>
                                    <label htmlFor="accion" className="input-group-text">Acción:</label>
                                    <SelectAccion
                                        id_materia={id_matter}
                                        id_tipo_juicio={formState.typeOfTrial}
                                        onChange={onInputChange}
                                        name={'accion'}
                                        disabled={parseInt(id_matter) && parseInt(formState.typeOfTrial) > 0 ? false : true}
                                        defaultValue={defaultValues?.accion}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mb-3 col-12 col-md-6">

                                <div className={'input-group mb-3'}>
                                    <label htmlFor="tipoJuicio" className="input-group-text">Expediente origen :</label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        onChange={onInputChange}
                                        name={'expediente_origen'}
                                        defaultValue={defaultValues?.expediente_origen}
                                    />
                                </div>

                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <div className={'input-group mb-3'}>
                                    <label htmlFor="accion" className="input-group-text">Juzgado origen:</label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        onChange={onInputChange}
                                        name={'juzgado_origen'}
                                        defaultValue={defaultValues?.juzgado_origen}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }

            </div>

            <div className={'row'}>
                {
                    chosen_matter == 'Familiar' ? (
                        <>
                            <div className="mb-3 col-12 col-md-6">

                                <div className={'input-group mb-3'}>
                                    <label htmlFor="representante_diff" className="input-group-text">Representante del Diff :</label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        id={'representante_diff'}
                                        onChange={onInputChange}
                                        name={'representante_diff'}
                                        defaultValue={defaultValues?.representante_diff}
                                    />
                                </div>

                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <div className={'input-group mb-3'}>
                                    <label htmlFor="representante_ministerio_publico" className="input-group-text">Representante del Ministerio Publico:</label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        id={'representante_ministerio_publico'}
                                        onChange={onInputChange}
                                        name={'representante_ministerio_publico'}
                                        defaultValue={defaultValues?.representante_ministerio_publico}
                                    />
                                </div>
                            </div>
                        </>
                    ) : chosen_matter == 'Agrario' && (
                        <>
                            <div className="mb-3 col-12 col-md-6">
                                <div className={'input-group mb-3'}>
                                    <label htmlFor="tua" className="input-group-text">TUA:</label>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        id={'tua'}
                                        onChange={onInputChange}
                                        name={'tua'}
                                        defaultValue={defaultValues?.tua}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>

        </div>
    )

}