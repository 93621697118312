
import {useState, useEffect} from "react";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {getYearsFrom} from '../../Components/PartesExpediente/helpers/getYearFrom';
import {useNavigate} from "react-router-dom";
import { NumExpediente } from "../../Components/PartesExpediente/NumExpediente";

export const ChooseTypeFileToRegister = () => {

    const { formState, onInputChange, onResetForm } = useForm();

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        console.log(formState);
        let { id, tipo_juicio, abreviatura } = JSON.parse(formState.matterLetter);
        navigate(`/registerFile/${id}/${abreviatura}/${tipo_juicio}/${formState.numberToFile}/${formState.yearToFile}`);
    }

    return (
        <div className={'d-flex m-auto justify-content-center align-items-center align-content-center m-auto '} style={{height: '80vh'}}>

            <div className={'container'}>

                <NumExpediente onInputChange={onInputChange} minLengthDigit={4} maxLengthDigit={4} matterDisable={false} matterDefaultValue={''} />

                <div className={'d-flex justify-content-end'}>
                    <button className={'btn btn-primary '} onClick={handleSubmit}> Crear expediente </button>
                </div>

            </div>

        </div>
    )
}