import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from '../../Auth'
import { NavBarClient } from '../../NavBars'
import { HomeClient } from '../HomeClient'

export const RoutesPublic = () => {
  return (
    <div>
      <NavBarClient />
      <div className=''>
        <Routes>
            <Route path="home" element={<HomeClient/>} />
            <Route path="login" element={<LoginPage/>} /> 

            <Route path="/*" element={<Navigate to="/public/home" />} />
        </Routes>
      </div>
    </div>
  )
}
