import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { DashboardMain } from '../Dashboard';
import { RegisterFile, RegisterClient, ChooseTypeFileToRegister } from '../Register';
import {ListExpedientes, ControlExpediente} from '../Control';
import {ConsultationByClients, ConsultationByCourts, ConsultationByLegalRepresentative, ConsultationByJudgeAndClerk} from '../Consultation';
import { ReminderSchedule } from '../Notifications';

import { NavBarAdmin } from '../../NavBars';
import { RoutesProfile } from '../../Profile/routes/RoutesProfile';
import {RoutesAdminSystem} from "../../AdminSystem/Routes/RoutesAdminSystem";

export const RoutesFileControl = () => {


    return (
        <>
            <NavBarAdmin />
            <div className="container">
                <Routes>

                    <Route path="dashboard" element={<DashboardMain />} />

                    <Route path="reminderSchedule" element={<ReminderSchedule/>} />

                    <Route path={'chooseTypeFileToRegister'} element={<ChooseTypeFileToRegister />} />
                    <Route path='registerFile/:id_matter/:abbreviation_matter/:chosen_matter/:digits/:year' element={<RegisterFile/>} />
                    <Route path='registerClient' element={<RegisterClient />} />

                    <Route path='listFiles' element={<ListExpedientes />} />
                    <Route path='controlFile/:expediente_id/:expediente_num/:id_cliente' element={<ControlExpediente />} />

                    <Route path='consultation/byCourts' element={<ConsultationByCourts/>} />
                    <Route path='consultation/byClients' element={<ConsultationByClients/>} />
                    <Route path='consultation/byJudgeAndClerk' element={<ConsultationByJudgeAndClerk/>} />
                    <Route path='consultation/byLeagalRepresentative' element={<ConsultationByLegalRepresentative/>} />

                    <Route
                        path='admin/*'
                        element={<RoutesAdminSystem />}
                    />

                    <Route
                        path='profile/*'
                        element={<RoutesProfile />}
                    />

                    <Route path='/*' element={<Navigate to='/dashboard' />} />
                </Routes>
            </div>
        </>
  )
}
