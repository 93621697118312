

import React, {useState, useMemo, useCallback, useRef} from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useDataCollectionRequest, useForm } from '../../../Hooks';
import { requestPost } from '../../../Helpers';
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {SelectMaterias} from "../../../Components/SelectMaterias";
import {SelectEstados} from "../../../Components/SelectEstados";
import {SelectCiudades} from "../../../Components/SelectCiudades";
import {SelectJuzgados} from "../../../Components/SelectJuzgados";
import {alertError, alertSuccess} from "../../../Helpers/Alerts";
import {FiEdit, FiSave} from "react-icons/fi";
import {BsTrash} from "react-icons/bs";
import {HiViewGridAdd} from "react-icons/hi";

export const RegisterJueces = () => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState({});

    const { dataCollectionRequest : getJueces, setDataCollectionRequest } = useDataCollectionRequest(
        'obtener_jueces',
        'all'
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterJuez = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const addRowToTable = useCallback((e) => {

        const getLastRow = gridRef.current.api.getLastDisplayedRow();

        const newRow = [
            {
                id: null,
                nombres: ' Juez ' + (getLastRow + 1),
                apellidos: '',
                num_celular: '',
                correo: '',
                estatus: '',
                id_juzgado: '',

            }
        ]

        gridRef.current.api.applyTransaction({ add: newRow });

    } , []);

    const addJuezOMagistrado = (data) => {


        const formData = new FormData();
        formData.append('juez_info' , JSON.stringify(data));

        requestPost('agregar_juez', formData)
            .then (response => {
                if (response > 0) {
                    alertSuccess('Guardado','Juez o Magistrado agregado correctamente')
                    setDataUpdate(!dataUpdate)
                } else {
                    alertError('Error','No se pudo agregar el Juez o Magistrado')
                }
            })
    }

    const updateJuezOMagistrado = (data) => {

        const formData = new FormData();
        formData.append('juez_info' , JSON.stringify(data));

        requestPost('actualizar_juez', formData)
            .then (response => {
                if (response > 0) {
                    alertSuccess('Actualizado','Juez o Magistrado actualizado correctamente')
                } else {
                    alertError('Error','No se pudo actualizar el Juez o Magistrado')
                }
            } )

    }

    const handleDeleteJuezOMagistrado = (data) => {

        const formData = new FormData();
        formData.append('juez_id' , data.id);

        requestPost('eliminar_juez', formData)
            .then (response => {
                if (response > 0) {
                    alertSuccess('Eliminado','Juez o Magistrado eliminado correctamente')
                    setDataUpdate(!dataUpdate)
                } else {
                    alertError('Error','No se pudo eliminar el Juez o Magistrado')
                }
            } )
    
    }

    return (
        <div className='' style={containerStyle}>
            
            <h3 className='m-3 mb-4 text-center'>Registrar Jueces o Magistrados</h3>

            <form className='d-flex flex-column row'>

                <div className='w-75 m-auto'>
                    <SearchDataToTable name={'Buscar juzgado: '} onFilter={onFilterJuez} />
                </div>

                <div className='d-flex justify-content-end '>
                    <button
                        className='btn btn-primary btn-md w-25 me-4'
                        onClick={ (event) => {
                            event.preventDefault()
                            addRowToTable()
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Juez o Magistrado
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombres', headerName: 'Nombre' },
                        { field: 'apellidos', headerName: 'Apellidos' },
                        { field: 'num_celular', headerName: 'Num Celular' },
                        { field: 'correo', headerName: 'Correo' },
                        {
                            field: 'estatus',
                            headerName: 'Estatus',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <select
                                        className='form-select'
                                        aria-label='Default select example'
                                        name='estatus'
                                        onChange={ (e) => {
                                            data.estatus = e.target.value
                                        } }
                                    >
                                        <option selected>Selecciona el estatus</option>
                                        <option value='Activo' selected={data.estatus == 'Activo'} >Activo</option>
                                        <option value='Inactivo' selected={data.estatus == 'Inactivo'}>Inactivo</option>
                                    </select>
                                )
                            }
                        },
                        {
                            field: 'id_materia',
                            headerName: 'Materia',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectMaterias
                                        name='id_materia'
                                        onChange={ (e) => {
                                            data.id_materia = JSON.parse(e.target.value)?.id;
                                        } }
                                        defaultValue={data.materia}
                                        matterDisable={false}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_estado',
                            headerName: 'Estado',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectEstados
                                        name={'estado'}
                                        onChange={ (e) => {
                                            data.id_estado = e.target.value;
                                        }}
                                        defaultValue={data.id_estado}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_ciudad',
                            headerName: 'Municipio',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectCiudades
                                        id_estado={data.id_estado}
                                        name={'id_ciudad'}
                                        onChange={ (e) => {
                                            data.id_ciudad = e.target.value;
                                        }}
                                        defaultValue={data.id_ciudad}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_juzgado',
                            headerName: 'Juzgado',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectJuzgados
                                        id_ciudad={data.id_ciudad}
                                        id_estado={data.id_estado}
                                        id_materia={ data.id_materia }
                                        name={'juzgado'}
                                        onChange={(e) => {
                                            data.id_juzgado = e.target.value;
                                        }}
                                        defaultValue={data.id_juzgado}
                                    />
                                )
                            }
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {

                                const { data } = params;

                                return (
                                    <div className={'d-flex justify-content-center gap-3'}>

                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? updateJuezOMagistrado(data) : addJuezOMagistrado(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                console.log(data)
                                                handleDeleteJuezOMagistrado(data.id)
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getJueces}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                />

            </div>

            <div className='m-3'>

            </div>

        </div>
    )
}
