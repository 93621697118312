import {requestPost} from "../../../../Helpers";
import {alertToast} from "../../../../Helpers/Alerts";

export const deleteClient = (id) => {

    const formData = new FormData();
    formData.append('id_cliente', id);

    requestPost('eliminar_cliente', formData)
        .then(res => {
            if (res == 1) {
                alertToast('El cliente fue eliminado correctamente', 'success');
            } else {
                alertToast('No se pudo eliminar el cliente', 'error');
            }
        }
    )
}