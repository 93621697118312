import {useDataCollectionRequest} from "../Hooks";


export const SelectEstados = ({ onChange = ()=>{}, defaultValue, name = '' }) => {

    const { dataCollectionRequest: getEstados } = useDataCollectionRequest(
        'obtener_estados',
        'all'
    );

    return (
        <select
            className='form-select'
            name={name}
            onChange={ (e) => {
                onChange(e);
            } }
        >
            <option value={''}>Selecciona un estado</option>
            {
                getEstados?.map((estado) => (
                    <option key={estado.id} value={estado.id} selected={estado.id == defaultValue} >{estado.nombre}</option>
                ))
            }
        </select>
    )

}