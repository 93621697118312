import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Admin} from "../Admin";
import {RegisterUser} from "../ManageUsers/RefisterUser";
import {PermissionUsers} from "../ManageUsers/PermissionUsers";
import {TableUsers} from "../ManageUsers/TableUsers";
import { RegisterJueces } from "../AdminExpedientes/Jueces/RegisterJueces";
import { RegisterJuzgados } from "../AdminExpedientes/Juzgados/RegisterJuzgados";
import { RegisterMaterias } from "../AdminExpedientes/RegisterMaterias";
import { RegisterTipoJuicio } from "../AdminExpedientes/RegisterTipoJuicio";
import { RegistrarAcciones } from "../AdminExpedientes/Acciones/RegistrarAcciones";
import { ViewExpedientes } from "../AdminExpedientes/ViewExpedientes";
import {Clientes} from "../AdminExpedientes/Clientes";
import {Ubicaciones} from "../AdminExpedientes/Luagres/Ubicaciones";


export const RoutesAdminSystem = () => {


    return (
        <div>
            <Routes
                // basename={'/admin'}
            >

                <Route path={'dashboard-admin'} element={<Admin />} />
                <Route path={'register-users'} element={<RegisterUser />} />
                <Route path={'permission-users'} element={<PermissionUsers />} />
                <Route path={'view-users'} element={<TableUsers />} />

                <Route path={'reports'} element={<h1>Reports</h1>} />

                <Route path={'register-jueces'} element={<RegisterJueces />} />
                <Route path={'register-juzgado'} element={<RegisterJuzgados />} />
                <Route path={'register-matter'} element={<RegisterMaterias />} />
                <Route path={'register-type-judge'} element={<RegisterTipoJuicio />} />
                <Route path={'register-acciones'} element={<RegistrarAcciones />} />
                <Route path={'view-files'} element={<ViewExpedientes />} />
                <Route path={'clientes'} element={<Clientes />} />
                <Route path={'register-location'} element={<Ubicaciones />} />
   
                <Route path='/*' element={<Navigate to='/admin/dashboard-admin' />} />
            </Routes>
        </div>
    )
}