import React, { useCallback, useMemo, useRef, useState } from 'react';
import {BsTrash} from 'react-icons/bs';
import {Table} from "../../Components";
import {useDataCollectionRequest} from "../../Hooks";
import {useNavigate} from "react-router-dom";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { SearchDataToTable } from '../../Components/SearchDataToTable';
import {TableClients} from "../Components/Clients/Components/TableClients";


export const ConsultationByClients = () => {


    return (
        <div  className="container container-md">

            <h2 className="text-center m-3">Consulta por Cliente</h2>

            <TableClients
                hideBtn={{
                    delete: false,
                    update: false,
                    add: false,
                }}
            />

        </div>
    );
}
