import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TableClients} from "../../../Components/Clients/Components/TableClients";

export const ViewClients = () => {


    return (
      <div className="container container-md mt-4">

          <h2 className="text-center mb-3">Lista de Clientes</h2>

          <TableClients
            hideBtn={{
                delete: false,
                update: false,
                add: false,
            }}
          />

      </div>
    );
}
