import {mandatario_judicial0asesor_particular} from "./helpers/mandatario_judicial0asesor_particular";

const valuesByDefault = {
    typeClient_contraparte: '',
    representate_procuraduria: '',
    autoridades_responsables: '',
    legalRepresentative: '',
}

export const ContraParte = ({onInputChange, formState, tipo_cliente, chosen_matter, defaultValues = valuesByDefault}) => {

    return (
        <div className={'row'}>

            <h3 className="col-12 text-center text-black-50 mb-2">Contra parte:</h3>

            <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="inputGroupSelect01">Tipo: </label>
                <select className="form-select" id="inputGroupSelect01" name='typeClient_contraparte' onChange={onInputChange}>
                    <option selected>Escoje una opción</option>
                    {
                        chosen_matter === 'Amparo' ? (
                            ['Quejoso', 'Tercero perjudicable'].map( (tipo, index) => {
                                if (tipo_cliente === tipo) {
                                    return null;
                                }
                                return (
                                    <option key={index} value={tipo} selected={defaultValues?.typeClient_contraparte == tipo} > {tipo} </option>
                                )
                            } )
                            ) :
                        chosen_matter === 'Administrativo'
                            ?
                                (
                                    <option value='Demandado'>Demandado</option>
                                )
                            :
                                ['Actor', 'Demandado', 'Promovente'].map((tipo, index) => {
                                    if (tipo_cliente === tipo) {
                                        return null;
                                    }
                                    return (
                                        <option
                                            key={index}
                                            value={tipo}
                                            selected={defaultValues?.typeClient_contraparte == tipo}
                                        >
                                            {tipo}
                                        </option>
                                    )
                                })
                    }
                </select>
            </div>

            {
                /* Si el cliente es actor o demandado o promovente entonces */
                formState?.typeClient_contraparte !== 'Promovente'
                    ?
                    <>
                        <div className="row mt-3">
                            {
                                chosen_matter == 'Agrario' && (
                                    <div className="mb-3 col-12 col-md-6">
                                        <div className='input-group mb-3'>
                                            <label htmlFor='legalRepresentative' className='input-group-text'> Representate de la procuraduria agraria: </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Mandatario Judicial'
                                                name='representate_procuraduria'
                                                id='legalRepresentative'
                                                defaultValue={defaultValues?.representate_procuraduria}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                chosen_matter == 'Administrativo' || chosen_matter == 'Amparo' && (
                                    <div className="mb-3 col-12 col-md-6">
                                        <div className='input-group mb-3'>
                                            <label htmlFor='' className='input-group-text'> Autoridad(es) responsable(s): </label>
                                            <textarea
                                                rows={3}
                                                className='form-control'
                                                placeholder='Autoridad(es) responsable(s)'
                                                name='autoridades_responsables'
                                                id=''
                                                defaultValue={defaultValues?.autoridades_responsables}
                                            ></textarea>
                                        </div>
                                    </div>
                                )

                            }
                            <div className='mb-3 col-12 col-md-6'>
                                <div className='input-group mb-3'>
                                    <label htmlFor='legalRepresentative' className='input-group-text'> { mandatario_judicial0asesor_particular(chosen_matter) }: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder={ mandatario_judicial0asesor_particular(chosen_matter) }
                                        name='legalRepresentative'
                                        id='legalRepresentative'
                                        defaultValue={defaultValues?.legalRepresentative}
                                    />
                                </div>
                            </div>
                        </div>

                    </>
                    :
                    /* Si el cliente es promovente entonces no se muestra ningun campo */
                    ''
            }
        </div>
    )
}