import logoLawyer from "../../Assets/img/Abogado_azuLOsLetra2a.png";
import optionProfile from "../../Assets/img/optionProfile.png";
import {useForm} from "../../Hooks";
import {useContext, useRef, useState} from "react";
import {requestPost} from "../../Helpers";
import {alertDialog} from "../../Helpers/Alerts";
import {AuthContext} from "../../Auth";


export const MyProfile = () => {

    const {dataForm, onInputChange} = useForm ();

    const {user} = useContext(AuthContext);

    const refInputImg = useRef(null);

    const [disableEdit, setDisableEdit] = useState(true);
    const [imgProfile, setImgProfile] = useState(optionProfile);
    const [getImgProfileToUpload, setGetImgProfileToUpload] = useState(null);

    const uploadImgProfile = () => {
        const formData = new FormData();
        formData.append('img-profile', getImgProfileToUpload);
        console.log('img profile to upload', formData);

        requestPost('http://localhost:8000/api/upload-img-profile', formData)
            .then((response) => {
                console.log('response', response);
            } )
            .catch((error) => {
                console.log('error', error);
            } )
    }

    const handleClickChangeImg = (e) => {
        refInputImg.current.click();
        console.log('img profile state', getImgProfileToUpload);
    }

    const handleUploadImg = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImgProfile(reader.result);
            setGetImgProfileToUpload(file);

            alertDialog('¿Desea cambiar la imagen de perfil?', 'Cambiar imagen', 'Cancelar')
                .then((result) => {
                    if (result.isConfirmed) {
                        uploadImgProfile();
                    } else {
                        setImgProfile(optionProfile)
                    }
                })
        }
    }


    return (
        <div className={'row justify-content-center align-content-center '}>

            <div className={'mt-5'}></div>

            <div className={'col-md-6 col-12 d-xs-none d-sm-none d-md-flex '}>
                <div className="d-flex align-middle  justify-content-center align-content-center ">
                    <img src={logoLawyer} alt="mdo" className="col-md-12 p-5 m-auto "  />
                </div>
            </div>

            <div className={'col-md-6 col-12'}>

                <div className={'d-flex flex-column align-middle  justify-content-center align-content-center '}>

                    <h3 className={'text-center'} > Información personal </h3>

                    <div className={'figure-img m-2 d-flex justify-content-center'}>
                        <img
                            className={'img-fluid rounded-3'}
                            src={imgProfile}
                            alt={''}
                            style={{ width: '190px', height: '190px', borderRadius: '50%', backgroundSize: 'cover' }}
                            onClick={handleClickChangeImg}
                        />
                        <input
                            type={'file'}
                            ref={refInputImg}
                            onChange={handleUploadImg}
                            className={'d-none'}
                            name={'img-profile[]'}
                        />
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" defaultValue={user?.nombres} name={'nombre'} disabled={disableEdit} />
                        <label htmlFor="floatingInput"> Nombre(s): </label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" defaultValue={user?.apellidos} name={user?.apellidos} disabled={disableEdit} />
                        <label htmlFor="floatingInput"> Apellidos: </label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput" defaultValue={user?.correo} name={'correo'}  disabled={disableEdit} />
                        <label htmlFor="floatingInput"> Correo: </label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" defaultValue={user?.rol} disabled={disableEdit} />
                        <label htmlFor="floatingInput"> Tipo de usuario: </label>
                    </div>

                    <div className={'row '}>
                        <div className={'col-md-6'}>
                            <div className={'d-flex justify-content-center '}>
                                <button
                                    className={'m-2 btn '.concat( disableEdit ? 'btn-primary' : 'btn-danger') }
                                    onClick={()=>setDisableEdit(!disableEdit)}
                                >
                                    {disableEdit ? 'Editar' : 'Cancelar' }
                                </button>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'d-flex justify-content-center '}>
                                <button
                                    className={'m-2 btn btn-success'}
                                    disabled={disableEdit}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </div>
    )
}