import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { RoutesFileControl } from '../FileControl';
import { RoutesPublic } from '../Public';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

const Routes_Nav = () => {


    return (
      <Routes>

        <Route 
          path="public/*"
          element={
            <PublicRoutes>
              <RoutesPublic/>
            </PublicRoutes>
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoutes>
              <RoutesFileControl/>
            </PrivateRoutes>
          }
        />

        <Route path="/" element={<Navigate to="/home" />} />
      </Routes>
  )
}

export default Routes_Nav;