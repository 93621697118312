import React from 'react'

export const Presentation = ({logoLawyer, text_title, text_body, phone}) => {
  return (
    <div className='row mt-5'>
          <div className='col-12 col-md-6 d-flex justify-content-center align-content-center'>
            <img 
              src={logoLawyer} 
              alt='' 
              className='mw-100'  
            />
          </div>
          <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-content-center'>
            <h3 className='text-center'>
              {text_title}
            </h3>
            <p className='text-center'> 
                {text_body}
            </p>
            <a
              href={phone} 
              className='btn btn-toolbar bg-info justify-content-center w-75 mx-auto me-auto'
            >
              Contactar
            </a>
          </div>
    </div>
  )
}
