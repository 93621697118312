import {useState} from 'react'
import {EditExpediente} from './Components/EditExpediente';
import { EditClient } from './Components/EditClient';
import { PaymentControl } from './Components/PaymentControl';
import { ToDo } from './Components/ToDo';
import {ObservationsToExpediente} from "./Components/ObservationsToExpediente";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import queryString from 'query-string';
import {MovementIndex} from "./Components/MovementIndex";
import { ExhortoExpediente } from './Components/ExhortoExpediente';

export const ControlExpediente = () => {

    const {expediente_num} = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const { q = '' } = queryString.parse(location.search);

    return (
        <div className='container mt-2'>
            <h5 className={'text-end fs-5 mx-4 p-0 mb-0'} > { expediente_num }  </h5>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                        className={"nav-link ".concat(q === 'edit-expediente' ? 'active' : '')}
                        id="nav-editExpediente-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-editExpediente"
                        type="button" role="tab"
                        aria-controls="nav-editExpediente"
                        aria-selected={q === 'edit-expediente' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=edit-expediente`)}}
                    >
                        Editar Expediente
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'edit-client' && 'active' ) }
                        id="nav-editClient-tab" data-bs-toggle="tab" data-bs-target="#nav-editClient"
                        type="button" role="tab" aria-controls="nav-editClient"
                        aria-selected={q === 'edit-client' ? 'true' : 'false'}
                            onClick={() => {navigate(`?q=edit-client`)}}
                    >
                        Editar Cliente
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'payment-control' && 'active' )}
                        id="nav-paymentControl-tab" data-bs-toggle="tab" data-bs-target="#nav-paymentControl"
                        type="button" role="tab" aria-controls="nav-paymentControl"
                        aria-selected={q === 'payment-control' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=payment-control`)}}
                    >
                        Control de Pagos
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'to-do' && 'active' )}
                        id="nav-toDo-tab" data-bs-toggle="tab" data-bs-target="#nav-toDo"
                        type="button" role="tab" aria-controls="nav-toDo"
                        aria-selected={q === 'to-do' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=to-do`)}}
                    >
                        Lista de tareas
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'movement-index' && 'active' )}
                        id="nav-movementIndex-tab" data-bs-toggle="tab" data-bs-target="#nav-movementIndex"
                        type="button" role="tab" aria-controls="nav-movementIndex"
                        aria-selected={q === 'movement-index' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=movement-index`)}}
                    >
                        Indice de movimientos
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'observations' && 'active' )}
                        id="nav-observations-tab" data-bs-toggle="tab" data-bs-target="#nav-observations"
                        type="button" role="tab" aria-controls="nav-observations"
                        aria-selected={q === 'observations' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=observations`)}}
                    >
                        Observaciones
                    </button>
                    <button
                        className={"nav-link ".concat( q === 'exhorto' && 'active' )}
                        id="nav-exhorto-tab" data-bs-toggle="tab" data-bs-target="#nav-exhorto"
                        type="button" role="tab" aria-controls="nav-exhorto"
                        aria-selected={q === 'exhorto' ? 'true' : 'false'}
                        onClick={() => {navigate(`?q=exhorto`)}}
                    >
                        Exhorto
                    </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div
                    className={"tab-pane fade".concat(q === 'edit-expediente' && 'show active' ) }
                    id="nav-editExpediente"
                    role="tabpanel"
                    aria-labelledby="nav-editExpediente-tab"
                    tabIndex="0"
                >
                    <EditExpediente />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'edit-client' && 'show active' ) }
                    id="nav-editClient"
                    role="tabpanel"
                    aria-labelledby="nav-editClient-tab"
                    tabIndex="0"
                >
                    <EditClient />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'payment-control' && 'show active' ) }
                    id="nav-paymentControl"
                    role="tabpanel"
                    aria-labelledby="nav-paymentControl-tab"
                    tabIndex="0"
                >
                    <PaymentControl />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'to-do' && 'show active' ) }
                    id="nav-toDo"
                    role="tabpanel"
                    aria-labelledby="nav-toDo-tab"
                    tabIndex="0"
                >
                    <ToDo />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'movement-index' && 'show active' ) }
                    id="nav-movementIndex"
                    role="tabpanel"
                    aria-labelledby="nav-movementIndex-tab"
                    tabIndex="0"
                >
                    <MovementIndex />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'observations' && 'show active' ) }
                    id="nav-observations"
                    role="tabpanel"
                    aria-labelledby="nav-observations-tab"
                    tabIndex="0"
                >
                    <ObservationsToExpediente />
                </div>
                <div
                    className={"tab-pane fade".concat(q === 'exhorto' && 'show active' ) }
                    id="nav-exhorto"
                    role="tabpanel"
                    aria-labelledby="nav-exhorto-tab"
                    tabIndex="0"
                >
                    <ExhortoExpediente />
                </div>
            </div>

        </div>
    )
}
