
import React from 'react'

export const DatingAlerts = () => {
  return (
    <div className="alert alert-primary" role="alert">
        Alerta de citas
    </div>
  )
}
