import { DatingAlerts } from "./DatingAlerts"
import { AudienceAlert } from "./AudienceAlert"
import { DocumentDeliveryAlert } from "./DocumentDeliveryAlert"

export const ReminderSchedule = () => {
  


    return (
        <div className="container container-md">
            <h2 className="text-center m-4"> Los recordatorios son: </h2>

            <div className="row">
                <DatingAlerts/>
                <AudienceAlert/>
                <DocumentDeliveryAlert/>
            </div>

        </div>
    )
}
