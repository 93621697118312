import React, { useCallback, useMemo, useRef, useState } from 'react';
import {BsTrash} from 'react-icons/bs';
import {FiEdit, FiSave} from 'react-icons/fi';
import {useDataCollectionRequest} from "../../../../Hooks";
import {useNavigate} from "react-router-dom";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { SearchDataToTable } from '../../../../Components/SearchDataToTable';
import {addClient, deleteClient, updateClient} from "../Helpers";
import {HiViewGridAdd} from "react-icons/hi";

const hiddeBtns = {
    delete: true,
    update: false,
    add: true,
};

export const TableClients = ( {hideBtn = hiddeBtns,  } ) => {

    const navigate = useNavigate();

    const gridRef = useRef();

    const { dataCollectionRequest: getClients } = useDataCollectionRequest('obtener_clientes', 'all');

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterClient = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const addRowToTable = useCallback((e) => {

        const getLastRow = gridRef.current.api.getLastDisplayedRow();

        const newRow = [
            {
                id: null,
                nombres: ' Cliente ' + (getLastRow + 1),
                apellidos: '',
                telefono: '',
                correo: '',
                direccion: '',
                colonia: '',
                ciudad: '',
                estado: '',
                cp: '',
                rfc: '',
            }
        ]

        gridRef.current.api.applyTransaction({ add: newRow });

    } ,[]);

    return (
        <div style={containerStyle} className="container container-md">

            <form className="row m-auto mb-3">

                <div className={'m-auto w-75'}>
                    <SearchDataToTable name={'Buscar cliente: '} onFilter={onFilterClient} />
                </div>

                {
                    hideBtn.add && (
                        <div className={'d-flex justify-content-end'}>
                            <button
                                className={'btn btn-primary btn-md w-25'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addRowToTable();
                                } }
                            >
                                <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar cliente
                            </button>
                        </div>
                    )
                }

            </form>

            <div style={gridStyle} className={'ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    rowData={getClients || []}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombres', headerName: 'Nombre(s)', editable: hideBtn.update, },
                        { field: 'apellidos', headerName: 'Apellidos', editable: hideBtn.update, },
                        { field: 'CURP', headerName: 'CURP', editable: hideBtn.update, },
                        { field: 'num_celular', headerName: 'Teléfono:', hide: true, editable: hideBtn.update, },
                        { field: 'num_celular_contacto', headerName: 'Teléfono de contacto:', editable: hideBtn.update, },
                        { field: 'direccion', headerName: 'Dirección', editable: hideBtn.update, },
                        { field: 'rfc', headerName: 'RFC', editable: hideBtn.update, },
                        { field: 'correo', headerName: 'Email', editable: hideBtn.update, },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            hide: !hideBtn.delete || !hideBtn.add,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <div className={'d-flex justify-content-center gap-3'}>
                                        {
                                            hideBtn.add && (
                                                <button
                                                    className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                                    onClick={ (event) => {
                                                        event.preventDefault()
                                                        data?.id ? updateClient(data.id, data) : addClient(data)
                                                    } }
                                                >
                                                    {
                                                        data?.id ?
                                                            <FiEdit className={'fs-5 m-auto mx-1'} />
                                                            :
                                                            <FiSave className={'fs-5 m-auto mx-1'} />
                                                    }
                                                </button>
                                            )
                                        }
                                        {
                                            hideBtn.delete && (
                                                <button
                                                    className='btn btn-danger w-100'
                                                    onClick={ () => {
                                                        console.log(data)
                                                        deleteClient(data.id)
                                                    } }
                                                >
                                                    <BsTrash className={'fs-5 m-auto mx-1'} />
                                                </button>
                                            )
                                        }


                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </div>

        </div>
    );
}
