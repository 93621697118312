import React, {useState, useCallback, useMemo, useRef} from 'react'
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {alertDialog, alertError, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {TableEdit} from "../../../Components/Tables/TableEdit";
import {baseUrl} from "../../../shared";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { BsTrash } from 'react-icons/bs';
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {IconToInputFile} from "../../../Components/IconToInputFile";
import {MdPreview} from "react-icons/md";
import {FiEdit, FiSave} from "react-icons/fi";
import data from "bootstrap/js/src/dom/data";
import {HiViewGridAdd} from "react-icons/hi";


export const MovementIndex = () => {

    const { expediente_id } = useParams();

    const gridRef = useRef();

    const [updateData, setUpdateData] = useState(false);

    const { formState, onResetForm, onInputChange  } = useForm({});

    const { dataCollectionRequest : getMovementsIndex, setDataCollectionRequest } = useDataCollectionRequest(
        `obtener_indice_movimiento_por_expediente&expediente_id=${expediente_id}`,
        'all',
        updateData
    );

    const [getfile, setFile] = useState(null);

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterMovementIndex = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const uploadFile = (e) => {
        setFile(e.target.files[0]);
    }

    const handleAddMovementIndex = (rowData) => {

        console.log('handleAddPayment');

        if (getfile === null) {
            alert('Debe seleccionar un archivo');
            return;
        }

        const formData = new FormData();
        formData.append('indice_movimiento_info', JSON.stringify(rowData));
        formData.append("file_info", getfile);

        requestPost('agregar_indice_movimiento', formData)
            .then((response) => {
                console.log(response);
                if (response > 0) {
                    alertSuccess('Exito','Pago agregado correctamente');
                    setUpdateData(!updateData);
                } else {
                    alertError('Error','No se ha podido agregar el indice de movimiento'
                    + ' verifique que el archivo no sea mayor a 2MB');
                }
            } )
            .catch((error) => {
                console.log(error);
            });

    }

    const handleDeleteMovementIndex = (dataRow) => {

        const body = {
            id: dataRow.id,
        }

        const formData = new FormData();
        formData.append('id_indice_movimeinto', JSON.stringify(body));

        alertDialog('¿Está seguro de eliminar el indice de movimiento?', 'Si, eliminar', 'Cancelar')
            .then((response) => {
                if (response) {
                    requestPost('eliminar_indice_movimiento', formData)
                    .then((response) => {
                        console.log(response);
                        if (response > 0) {
                            alertSuccess('Exito','Indice de movimiento eliminado correctamente');
                            setUpdateData(!updateData);
                        } else {
                            alertError('Error','No se ha podido eliminar el indice de movimiento');
                        }
                    }
                )
                }
            })

    }

    const handleEditMovementIndex = (e, dataRow) => {
        

        const formData = new FormData();
        formData.append('indice_movimiento_info', JSON.stringify(dataRow));
        formData.append("file_info", getfile);

        const nameService = getfile === null ? 'actualizar_indice_movimiento' : 'actualizar_document_e_info_por_indice_movimiento';

        requestPost(nameService, formData)
            .then((response) => {
                console.log(response);
                if (response) {
                    setUpdateData(!updateData);
                    alertToast('Actualizado correctamente');
                } else {
                    alertError('Error','No se ha podido actualizar el indice de movimiento');
                }
            }
        )

    }

    const addRowToTable = useCallback((e) => {

        const newItem = [
            {
                id: null,
                tipo: '',
                fecha_expedicion: '',
                resumen: '',
                ruta_archivo: '',
                id_expediente: parseInt(expediente_id),
            }
        ]

        gridRef.current.api.applyTransaction({ add: newItem });

    },[]);

    return (
        <div style={containerStyle} className={'container container-md mt-4 mb-3 '}>

            <div className={'row'}>

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar: '} onFilter={onFilterMovementIndex} />
                </div>

                <div className={'d-flex justify-content-end'} >
                    <button className={'btn btn-primary'} onClick={addRowToTable}>
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Indice de movimiento
                    </button>
                </div>

            </div>

            <hr className='mt-3 mb-3' />

            <div style={gridStyle} className={'ag-theme-alpine'}>

                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { headerName: 'ID', field: 'id', hide: true },
                        { headerName: 'Tipo', field: 'tipo', editable: true },
                        {
                            headerName: 'Fecha',
                            field: 'fecha_expedicion',
                            cellRenderer: (params) => {
                                const {data} = params;

                                return (
                                <input 
                                    type='date' 
                                    name='fecha_expedicion'
                                    onBlur={ (e) => {
                                        data.fecha_expedicion = e.target.value;
                                    } }
                                    className='form-control'
                                    defaultValue={data?.fecha_expedicion}
                                />
                                )
                            },
                        },
                        {
                            headerName: 'Resumen',
                            field: 'resumen',
                            editable: true,
                            cellEditor: 'agLargeTextCellEditor',
                            cellEditorPopup: true,
                            cellEditorParams: {
                                rows: 10,
                                cols: 50
                            },
                            flex: 2,
                        },
                        {
                            headerName: 'Archivo',
                            field: 'ruta_archivo',
                            cellRenderer: (params) => {
                                const {data} = params;

                                return (
                                    <div className={'d-flex justify-content-center gap-2'}>
                                        {
                                            data?.ruta_archivo !== '' && (
                                                <button
                                                    className='btn btn-link text-center w-100'
                                                    onClick={ () => {
                                                        console.log(data)
                                                        window.open(baseUrl + data?.ruta_archivo.slice(2, data.ruta_archivo.length), '_blank');
                                                    }}
                                                >
                                                    <MdPreview size={'1.7rem'} />
                                                </button>
                                            )
                                        }
                                        <IconToInputFile
                                            id="inputGroupFile01"
                                            name="files[]"
                                            onChange={uploadFile}
                                            sizeIcon={'1.7rem'}
                                            classNameIcon={'btn btn-link text-center w-100'}
                                            multiple={false}
                                        />
                                    </div>
                                )
                            },
                        },
                        { headerName: 'Expediente', field: 'id_expediente', hide: true },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <div className={'d-flex justify-content-center gap-3'}>

                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleEditMovementIndex(event, data) : handleAddMovementIndex(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                console.log(data)
                                                handleDeleteMovementIndex(data)
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                )
                            },

                        }
                    ]}
                    rowData={getMovementsIndex || []}
                />

            </div>


        </div>
    )
}