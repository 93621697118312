

export const juzgadoOsala = (matter) => {
    
    switch (matter) {
        case 'Agrario' || 'Administrativo':
            return 'Sala';
        default:
            return 'Juzgado';   
    }

}