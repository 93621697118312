import {NavLink} from "react-router-dom";


export const Admin = () => {



    return (
        <div className={'mt-4'}>

            <div className={''}>
                <h4 className={'fs-3 m-3 mb-5 text-center'}> Administración del sistema control de expedientes </h4>
            </div>

            <div className="d-flex row-cols-3">
                <div>
                    <h4> Administrar Usuarios </h4>
                    <ul className="nav flex-column">

                        <li className="nav-item">
                            <NavLink className="nav-link active" aria-current="page" to="/admin/view-users"> Ver usuarios </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/register-users"> Registrar usuarios </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link active" aria-current="page" to="/admin/permission-users"> Permisos de usuarios </NavLink>
                        </li>

                        <li className="nav-item">
                        </li>

                    </ul>
                </div>

                <div>
                    <h4> Reportes </h4>

                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/reports"> Reportes </NavLink>
                        </li>
                    </ul>

                </div>

                <div>
                    <h4> Administrar expedientes </h4>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink className="nav-link active" aria-current="page" to="/admin/view-files"> Ver expedientes </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/clientes"> Clientes </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link active" aria-current="page" to="/admin/register-matter"> Materias </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/register-jueces"> Jueces o Magistrados </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link " aria-current="page" to="/admin/register-juzgado"> Juzgados o Salas </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/register-type-judge"> Tipo de juicio </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/register-acciones"> Acción </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/register-location"> Estados, Ciudades y Poblados  </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/">   </NavLink>
                        </li>

                    </ul>
                </div>

            </div>


        </div>
    )
}
