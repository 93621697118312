import {useState, useEffect} from 'react'
import {AiOutlineUserAdd} from 'react-icons/ai';
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import { NumExpediente } from '../../Components/PartesExpediente/NumExpediente';
import {Catalogo, ContraParte, DatosCliente} from "../../Components/PartesExpediente";


export const EditExpediente = () => {

    const { expediente_id } = useParams();

    const { dataCollectionRequest: getFile } = useDataCollectionRequest(
        `obtener_expediente_por_id&expediente_id=${expediente_id}`,
        'row'
    );

    const { formState, onResetForm, onInputChange } = useForm({});

    const { dataCollectionRequest: getClients } = useDataCollectionRequest('obtener_clientes', 'all');
    const { dataCollectionRequest: getEstados } = useDataCollectionRequest('obtener_estados', 'all');
    const { dataCollectionRequest: getMunicipios } = useDataCollectionRequest(
        `obtener_ciudades_municipios_por_estado&id_estado=${formState?.cityState}`,
        'all'
    );
    const { dataCollectionRequest: getMaterias } = useDataCollectionRequest('obtener_materias', 'all');
    const { dataCollectionRequest: getJuzgados } = useDataCollectionRequest(
        `obtener_juzgados`,
        'all'
    );


    const onHandleUpdate = () => {
        console.log(formState);

        const body = {
            num_expediente: getFile?.num_expediente,
            tipo_cliente: formState.tipo_cliente,
            fecha_terminacion: formState.fecha_terminacion,
            estado_exp: formState.estado_exp,
            id_cliente: formState.id_cliente,
            id_materia: formState.id_materia,
            id_juzgado: formState.id_juzgado,
            id: parseInt(expediente_id),
        }

        const formData = new FormData();
        formData.append('expediente_info', JSON.stringify(body));

        requestPost('actualizar_expediente', formData)
            .then((response) => {

            } )
            .catch((error) => {
                console.log(error);
            } );

    }


    return (
        <div className='container container-md container-lg mt-4 mb-3 row'>
          <h2 className='text-center mt-2 mb-2 '>  </h2>

          <div className='row'>
              <form className='row'>

                <h3 className="col-12 text-center text-black-50 mb-2">Numero de expediente:</h3>

                <NumExpediente
                    onInputChange={onInputChange}
                    maxLengthDigit={6}
                    minLengthDigit={6}
                    matterDisable={true}
                    matterDefaultValue={getFile?.materia}
                    digitDefaultValue={getFile?.num_expediente.split('-')[1]}
                    yearDefaultValue={getFile?.num_expediente.split('-')[2]}
                />

                <DatosCliente
                    onInputChange={onInputChange}
                    chosen_matter={getFile?.materia}
                    defaultValues={{
                        tipo_cliente: getFile?.tipo_cliente,
                        client: getFile?.id_cliente,
                    }}
                />

                <Catalogo
                  formState={formState}
                  onInputChange={onInputChange}
                  chosen_matter={getFile?.materia}
                  id_matter={getFile?.id_materia}
                  defaultValues={{
                      cityState: getFile?.id_estado,
                      municipality: getFile?.id_municipio,
                      poblado: '',
                      court: getFile?.id_juzgado,
                      nameJudge: '',
                      nombre_secretario_del_juez: '',
                      typeOfTrial: '',
                      accion: '',
                      expediente_origen: '',
                      juzgado_origen: '',
                  }}
                />

                <ContraParte
                    formState={formState}
                    onInputChange={onInputChange}
                    tipo_cliente={formState?.tipo_cliente}
                    chosen_matter={getFile?.materia}
                    defaultValues={{
                        typeClient_contraparte: '',
                        representate_procuraduria: '',
                        autoridades_responsables: '',
                        legalRepresentative: '',
                    } }
                />

                <div className='row d-flex justify-content-end mt-3'>
                  <input type='button' value='Guardar' className='btn btn-success w-25' onClick={onHandleUpdate} />
                </div>

              </form>
          </div>
        </div>
    )
}
