import {useState, useEffect, useRef} from "react";
import {useDataCollectionRequest} from "../../../Hooks";
import {Table} from "../../../Components";


export const HistorialXPago = ({idTicket, dataTicket}) => {

    const { dataCollectionRequest : getHistorial } = useDataCollectionRequest(
        `obtener_abonos_por_ticket&ticket_id=${idTicket}`,
        'all'
    );

    useEffect(() => {
        console.log(dataTicket);

    } , [idTicket, dataTicket]);

    return (
        <div>

            <div className={''}>
                <h2 className={'text-center lead mt-2 mb-2 '}>{ dataTicket?.ticket }</h2>
                <p className={'ps-5'}>
                    Concepto: {dataTicket?.concepto}
                </p>
            </div>

            <div>
                <Table
                    getColumns={[
                        { headerName: 'id', field: 'id', hidden: true },
                        { headerName: 'Fecha', field: 'fecha' },
                        { headerName: 'Abono', field: 'abono' },
                    ]}
                    getRows={getHistorial || []}
                />
            </div>

            <div className={'row'}>

                <div className={'col'}>
                    <div className={'input-group'}>
                        <label htmlFor="montoTotal" className={'input-group-text'}>Total: </label>
                        <input type="text" name="montoTotal" id="montoTotal" className={'form-control'} value={'$'.concat(dataTicket?.monto_total)} disabled />
                    </div>
                </div>

                <div className={'col'}>
                    <div className={'input-group'}>
                        <label htmlFor="abono" className={'input-group-text'} >Abono: </label>
                        <input type="text" name="abono" id="abono" className={'form-control'} value={'$'.concat(dataTicket?.abono)} disabled />
                    </div>
                </div>

                <div className={'col'}>
                    <div className={'input-group'}>
                        <label htmlFor="restaria" className={'input-group-text'} >Restaría: </label>
                        <input
                            type="text"
                            name="restaria"
                            id="restaria"
                            className={'form-control'}
                            disabled
                            value={`$ ${ (( parseFloat(dataTicket?.monto_total) - ( parseFloat(dataTicket?.abono) ) || 0)).toFixed(2) }`}
                        />
                    </div>
                </div>

            </div>

        </div>
    )
}