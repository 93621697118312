import React from 'react'
import { requestPost } from '../../../../Helpers';
import { alertError, alertSuccess } from '../../../../Helpers/Alerts';
import { useForm } from '../../../../Hooks';

export const CreateClient = () => {

    const { formState, onInputChange } = useForm({});

    const onSubmit = (e) => {
        e.preventDefault();

        const bodyPost = {
            "id_client": -1,
            "nombres": formState.firstName,
            "apellidos": formState.lastName,
            "CURP": formState.curp,
            "num_celular": formState.phone,
            "num_celular_contacto": formState.phone_contact,
            "direccion": formState.address,
            "rfc": formState.rfc,
            "correo": formState.email,
        }

        console.log("bodyPost: ", bodyPost);
        
        const dataForm = new FormData();
        dataForm.append('client_info', JSON.stringify(bodyPost));

        requestPost('agregar_cliente', dataForm)
            .then(response => {
                if (response != '0') {
                    alertSuccess('Guardado con exito', 'Cliente agregado correctamente');
                } else {
                    alertError('Error', 'Error al agregar el cliente');
                }
                console.log("response: ", response);
            })
            .catch(error => {
                alertError('Error', 'Error al agregar el cliente');
                console.log("error: ", error);
            })

    }

    return (
        <div className='container container-md container-lg mt-2'>
        <h2 className='text-center mt-4 mb-4 '>Registrar Cliente</h2>
            
            <form className='row'>

                <div className='row'>
                    <div className='mb-3 col-12 col-md-6'>
                        <div className="input-group mb-3">
                            <label htmlFor='input-group-text' className='input-group-text'> Nombre(s): </label>
                            <input type='text' placeholder='Carlos' className='form-control' name='firstName' id='floatingInput' onChange={onInputChange} />
                        </div>
                    </div>
                    <div className='mb-3 12 col-md-6'>
                        <div className="input-group mb-3">
                            <label htmlFor='input-group-text' className='input-group-text'> Apellido(s): </label>
                            <input type='text' placeholder='Guevara' className='form-control' name='lastName' id='lastName' onChange={onInputChange} />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    <div className='mb-3 col-12 col-md-6'>
                        <div className="input-group mb-3">
                            <label className='input-group-text' htmlFor='CURP'>CURP: </label>
                            <input type='text' className='form-control' placeholder='Carlos' name='curp' id='CURP' onChange={onInputChange}/>
                        </div>
                    </div>

                    <div className='mb-3 col-12 col-md-6'>
                        <div className="input-group mb-3">
                            <label className='input-group-text' htmlFor='phone'>Telefono: </label>
                            <input type='tel' className='form-control' placeholder='462-111-1111' name='phone' id='phone' onChange={onInputChange} />
                        </div>
                    </div>

                </div>

                <div className='row'>
                    <div className='mb-3 col-12 col-md-6'>
                        <div className="input-group mb-3">
                            <label htmlFor='rfc' className='input-group-text'> RFC: </label>
                            <input type='text' placeholder='RFC' className='form-control' name='rfc' id='rfc' onChange={onInputChange} />
                        </div>
                    </div>
                    <div className='mb-3 col-12 col-md-6'>
                        <div className=' input-group mb-3 '>
                            <label htmlFor='contactPhone' className='input-group-text'> Telefono de contacto: </label>
                            <input type='tel' placeholder='462 111 1111' className='form-control' name='phone_contact' id='contactPhone' onChange={onInputChange}/>
                        </div>
                    </div>
                </div>

                <div className='mb-3'>
                    <div className='input-group mb-3'>
                        <label htmlFor='email' className='input-group-text'> Email: </label>
                        <input type='email' placeholder='correo@correo.com' className='form-control' name='email' id='email' onChange={onInputChange}/>
                    </div>
                </div>
                
                <div className='mb-3'>
                    <div className='input-group mb-3'>
                        <label className='input-group-text' htmlFor='address'>Dirección: </label>
                        <input type='text' className='form-control' placeholder='Blv. Diaz Ordaz, las rosas #332' name='address' id='address' onChange={onInputChange}/>
                    </div>
                </div>
                
                <div className='mb-3'>
                    <div className='input-group mb-3'>
                        <label className='input-group-text' htmlFor='INE'>INE: </label>
                        <input type='file' className='form-control' name='ine' id='INE' onChange={onInputChange}/>
                    </div>
                </div>
                
                <div className='mt-3 d-flex justify-content-end'>
                    <input type='button' value='Guardar' className='btn btn-success w-25' onClick={onSubmit}/>
                </div>

            </form>
        </div>
    )
}
