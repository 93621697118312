import {useState} from 'react'
import Calendar from 'react-calendar'
import { Chart } from "react-google-charts";
import { CardNumbers } from './Components'
import { CardHorizontal } from '../../Components'

import filesImg from '../../Assets/img/files.png';
import notificationsImg from '../../Assets/img/notifications.png';
import { NavLink } from 'react-router-dom';
import {useDataCollectionRequest} from "../../Hooks";


export const DashboardMain = () => {
  
    const { dataCollectionRequest: getCountClients } = useDataCollectionRequest('obtener_numero_total_clientes', 'row');
    const { dataCollectionRequest: getCountFiles } = useDataCollectionRequest('obtener_num_total_expedientes', 'row');


    const [date, setDate] = useState(new Date());

  return (
    <div className="row vh-100 justify-content-center align-items-center">
      <div>
        <h2>  </h2>
      </div>
      <div className="row">
        <div className="col-md-2">
          <CardNumbers number={getCountClients?.num_clientes_total} title="Clientes"/>
          <CardNumbers number={getCountFiles?.total_expedientes} title="Expedientes"/>
          <CardNumbers number={50} title="Contactos"/>    
        </div>
        <div className='col-md-5'>
          <CardHorizontal 
            img={filesImg}
            cardTitle='Expedientes'
            cardBody={
                (
                  <> 
                    <strong>
                      Acutalmente se encuentran en proceso 5 expedientes: 
                    </strong>
                    <ul>
                      <li> Expediente #433432 </li>
                    </ul>
                  </>
                )
            }
            cardParagraph={`Ultimo actualización: - ${date.toLocaleDateString()}`}
          />
          <CardHorizontal 
            img={notificationsImg}
            cardTitle='Notificaciones'
            cardBody= { (
              <>
                <p> Existen 10 notificaciones que tienes pendientes, deseas ir a verlas? </p>
                <NavLink to='/notifications' className='text-end text-muted '> Ver las notificaciones </NavLink>
              </>
            ) }
            cardParagraph={`Ultimo actualización: - ${date.toLocaleDateString()}`}
          />
          <CardHorizontal 
            img={notificationsImg}
            cardTitle='Expedientes'
            cardBody={(
              <p>Acutalmente se encuentran en proceso 5 expedientes</p>
            )}
            cardParagraph={`Ultimo actualización: - ${date.toLocaleDateString()}`}
          />
        </div>
        <div className='col-md-5'>
            <Calendar onChange={setDate} value={date} />
            {/* <Chart
              style={{"margin": "0", "padding": "0"}}
              chartType="BarChart"
              data={[["Age", "Weight"], [4, 5.5], [8, 12]]}
              width="100%"
              height="400px"
              legendToggle
            /> */}
        </div>
      </div>
      <div className='row'>
            <h3 className='text-center'> ¿Deseas eliminar los archivos de los expedientes? </h3>
            
      </div>
    </div>
  )
}
