import Swal from 'sweetalert2'

export const alertDialog = async (title, confirmButtonText, denyButtonText) => {
  return await Swal.fire({
        title: title,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
          return false;
        }
      })
}