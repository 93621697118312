import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {ContextFileControl} from './Context/contextFileControl';
// import Navigation from './Routes/Navigation';
import RoutesNav from './Router/Routes';

import {AuthProvider} from './Auth';

function App() {

  const [fileControl, setFileControl] = useState(null);

  return (
    <BrowserRouter>
    
      <AuthProvider>
        
        <ContextFileControl.Provider value={{fileControl, setFileControl}}>

          <RoutesNav />
          {/* <Navigation /> */}
        {/* <div className="d-flex flex-column ">
          <main className='container-fluid mw-100 ' style={{"height":"calc(100%-60px-70px)"}}>
          </main>
        </div> */}

        </ContextFileControl.Provider>
        
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
