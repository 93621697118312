import React, {useRef, useMemo, useState, useCallback} from 'react'
import {Table, TableAccordion} from "../../../Components";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {alertSuccess} from "../../../Helpers/Alerts";
import {HistorialXPago} from "./HistorialXPago";
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { SearchDataToTable } from '../../../Components/SearchDataToTable';
import {IoTicketOutline} from "react-icons/io5";
import {FaMoneyBillAlt} from "react-icons/fa";

export const PaymentControl = () => {

    const gridRef = useRef();

    const { expediente_id, id_cliente, expediente_num } = useParams();

    const { formState, onResetForm, onInputChange  } = useForm({});

    const { dataCollectionRequest : getPayments } = useDataCollectionRequest(
        `obtener_pagos_por_expediente&expediente_id=${expediente_id}`,
            'all',
    );

    const {dataCollectionRequest : getPayById} = useDataCollectionRequest(
        'obtener_info_del_pago_por_id&payment_control_id=' + formState.concepto,
            'row'
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const [historyDataPayment, setHistoryDataPayment] = useState({});

    const handleAddPayment = () => {

        console.log('handleAddPayment');

        const body = {
            concepto: formState.concepto,
            monto_total: parseFloat(formState.monto_total),
            abono: parseFloat(formState.abono),
            fecha: formState.fecha,
            id_expediente: parseInt(expediente_id),
            id_cliente: parseInt(id_cliente),
            num_expediente: expediente_num,
        }

        const formData = new FormData();
        formData.append('pago_info', JSON.stringify(body));

        requestPost('agregar_pago', formData)
            .then((response) => {
                alertSuccess('Exito','Pago agregado correctamente');
            } )
            .catch((error) => {
                console.log(error);
            });

    }

    const handleAddCreditToPayment = () => {

        const body = {
            concepto: formState.concepto,
            referencia_ticket: getPayById?.ticket,
            abono: parseFloat(formState.abono),
            id_control_pagos: parseInt(getPayById?.id),
        }

        console.log(body)

        const formData = new FormData();
        formData.append('abono_info', JSON.stringify(body));

        requestPost('registrar_abono', formData)
            .then((response) => {
                alertSuccess('Exito','Pago agregado correctamente');
            } )
            .catch((error) => {
                console.log(error);
            });

    }

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterPayment = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);
    

  return (
    <div style={containerStyle} className={'container container-md mt-4 mb-3 '}>

        <div className='row'>

            <div className="modal fade" id="addPagoTicketModal" aria-hidden="true" aria-labelledby="addPagoTicket"
                tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addPagoTicket"> Agregar Ticket </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <form className="mt-4 mb-3 row">

                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInput" name={'concepto'} onChange={onInputChange} />
                                        <label htmlFor="floatingInput">Concepto:</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingPassword" name={'monto_total'} onChange={onInputChange} />
                                        <label htmlFor="floatingPassword">Monto:</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInput" name={'abono'} onChange={onInputChange} />
                                        <label htmlFor="floatingInput">Abono:</label>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="date" className="form-control" id="floatingPassword" name={'fecha'} onChange={onInputChange} />
                                        <label htmlFor="floatingPassword">Fecha:</label>
                                    </div>
                                </div>

                            </form>

                        </div>
                        <div className="modal-footer">
                            <div className={'d-flex gap-3'}>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleAddPayment}
                                >
                                    Registrar ticket de pago
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addAbonoToTicketModal" aria-hidden="true" aria-labelledby="addAbonoToTicket"
                tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addAbonoToTicket"> Abono </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <form className="mt-4 mb-3 row">

                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name={'concepto'}
                                            onChange={onInputChange}
                                        >
                                            <option selected> Elija al concepto que realizará el abono </option>
                                            {
                                                getPayments &&
                                                getPayments.map((concept) => (
                                                    <option
                                                        key={concept.id}
                                                        value={concept.id}
                                                    >
                                                        {concept.concepto}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <label htmlFor="floatingInput">Concepto:</label>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="floatingTicket"
                                            name={'ticket'}
                                            value={getPayById?.ticket}
                                            disabled
                                        />
                                        <label htmlFor="floatingTicket"> Ticket :</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name={'abono'}
                                            onChange={onInputChange}
                                            disabled={formState?.concepto === null || formState?.concepto === undefined}
                                        />
                                        <label htmlFor="floatingInput">Abono:</label>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="floatingTicket"
                                            name={'monto_total'}
                                            value={`$ ${ getPayById?.monto_total || 0 }`}
                                            disabled
                                        />
                                        <label htmlFor="floatingTicket"> Monto total: </label>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>

                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            id="floatingTicket"
                                            name={'restante'}
                                            value={`$ ${ (( parseFloat(getPayById?.monto_total) - ( parseFloat(getPayById?.abono) + (parseFloat(formState.abono) || 0) ) || 0)).toFixed(2) }`}
                                            disabled
                                        />
                                        <label htmlFor="floatingTicket"> Restaría un total de: </label>
                                    </div>
                                </div>

                            </form>

                        </div>
                        <div className="modal-footer">
                            <div className={'d-flex gap-3'}>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleAddCreditToPayment}
                                >
                                    Agregar abono
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'d-flex gap-2 m-auto '}>
            
                <a className="btn btn-primary" style={{margin: 'auto 0'}} data-bs-toggle="modal" href="#addPagoTicketModal" role="button">
                    <IoTicketOutline className={'fs-5 mx-1 me-1'} /> Agregar ticket para pago
                </a>

                <a className="btn btn-primary" style={{margin: 'auto 0'}} data-bs-toggle="modal" href="#addAbonoToTicketModal" role="button">
                    <FaMoneyBillAlt className={'fs-5 mx-1 me-1'} /> Agregar abono
                </a>

                <div className='w-50 m-auto'>
                    <SearchDataToTable name={'Buscar pago'} onFilter={onFilterPayment}  />
                </div>

            </div>
        </div>
        
        <hr className={'mt-3'} />

        <div className="modal  fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                {'Historial de pagos'}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                <HistorialXPago idTicket={historyDataPayment.id} dataTicket={historyDataPayment} />
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>{}}>
                                {'Otro'}
                            </button>
                        </div>
                    </div>
                </div>
        </div>

      <div style={gridStyle} className="row ag-theme-alpine" >
        <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            rowData={getPayments || []}
            columnDefs={[
                { headerName: 'id', field: 'id', hide: true },
                { headerName: 'Ticket', field: 'ticket', relationalList: 'ticket' },
                { headerName: 'Concepto', field: 'concepto'},
                { headerName: 'Total', field: 'monto_total' },
                { headerName: 'Abono', field: 'abono' },
                { headerName: 'Estado del pago', field: 'estado_pago' },
                { headerName: 'Fecha', field: 'fecha' },
                { headerName: 'Id Expediente', field: 'id_expediente', hide: true },
                {
                    headerName: 'Editar',
                    field: 'editar',
                    cellRenderer: (params) => {
                        const {data} = params;
                        return (
                            <button 
                                className={"btn btn-secondary "} 
                                data-bs-toggle="modal" 
                                data-bs-target="#exampleModal" 
                                onClick={()=>{
                                    console.log(data)
                                    setHistoryDataPayment(data)
                                }}
                            >
                                {'Abrir historial'}
                            </button>
                        )
                    },
                }
            ]}
        />
      </div>

    </div>
  )
}
