import { useState, useRef, useMemo, useCallback } from "react";
import {useDataCollectionRequest} from "../../Hooks";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { SearchDataToTable } from "../../Components/SearchDataToTable";


export const TableUsers = () => {

    const gridRef = useRef();

    const {dataCollectionRequest: getUsers} = useDataCollectionRequest(
        'obtener_usuarios',
        'all'
    );

    const { dataCollectionRequest: getRoles } = useDataCollectionRequest('obtener_roles', 'all');

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterClient = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    return (
        <div className={'mt-4'} style={containerStyle}>

            <h4 className={'fs-3 m-3 text-center'}> Usuarios registrados </h4>

            <form>
                <SearchDataToTable name={'Buscar usuario: '} onFilter={onFilterClient} />
            </form>

            <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    rowData={getUsers || []}
                    columnDefs={[
                        { headerName: 'Nombre', field: 'nombre' },
                        { headerName: 'Apellidos', field: 'apellidos' },
                        { headerName: 'Correo', field: 'correo' },
                        { headerName: 'Celular', field: 'num_celular' },
                        {
                            headerName: 'Rol',
                            field: 'id_rol',
                            cellRenderer: (params) => {
                                const { value } = params;

                                return (
                                    <select 
                                        className={'form-select w-100'}
                                        onChange={ ({target}) => {console.log(target)} }
                                    >
                                        {
                                            getRoles?.map( (role) => (
                                                <option 
                                                    key={role.id} 
                                                    value={role.id} 
                                                    selected={role.id === value}
                                                >
                                                    { role.nombre }
                                                </option>
                                            ))
                                        }
                                    </select>
                                )

                            },
                        },
                        {
                            headerName: 'Editar',
                            field: 'editar',
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <button
                                        className={'btn btn-primary w-100'}
                                        onClick={() => console.log(data)}
                                    >   
                                        Editar
                                    </button>
                                )
                            },
                        },
                        {
                            headerName: 'Eliminar',
                            field: 'eliminar',
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <button
                                        className={'btn btn-danger w-100'}
                                        onClick={() => console.log(data)}
                                    >
                                        Eliminar
                                    </button>
                                )
                            },
                        }
                    ]}
                />
            </div>

        </div>
    )
}