import { useContext } from "react"
import { Navigate } from "react-router-dom";
import { AuthContext } from "../Auth/Context";


export const PrivateRoutes = ({children}) => {

    const {logged} = useContext(AuthContext);

    return (logged)
        ? children
        : <Navigate to="/public/home"/>

}