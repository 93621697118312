import React from 'react'

export const Testimonials = ({text_title, text_body, text_footer}) => {
  return (
    <div className="card col-12 col-md-5">
        <div className="card-header">
            {text_title}
        </div>
        <div className="card-body">
        <blockquote className="blockquote mb-0">
            <p>{text_body}</p>
            <footer className="blockquote-footer"> {text_footer} </footer>
        </blockquote>
        </div>
    </div>
  )
}
