import {useRef} from "react";
import {FcSearch} from "react-icons/fc";

export const SearchDataToTable = ({name, onFilter}) => {

    const inputRef = useRef();

    const handleFocusInput = (e) => {
        e.preventDefault();
        inputRef.current.focus();
    }

    return (
        <div className="input-group mb-3">
            <label className="input-group-text" htmlFor={"inputGroupFile-"+name}> {name} </label>
            <input type="search" ref={inputRef} className="form-control" id={"inputGroupFile-"+name} onChange={onFilter} />
            <button className="input-group-text" onClick={handleFocusInput} > <FcSearch className={'fs-5 mx-1 me-1'} /> </button>
        </div>
    )
}