import {Table} from "../../Components";


export const PermissionUsers = () => {

        return (
            <div className={'mt-4'}>
                <h4 className={'fs-3 m-3 text-center'}> Permisos de usuarios </h4>


                <div>
                    <Table
                        getColumns={[
                            {headerName: 'Rol', field: 'rol'},
                            {
                                headerName: '',
                                field: '',
                                type: '',
                                label: '',
                            }

                        ]}

                    />
                </div>


            </div>
        )
}