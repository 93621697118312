import {useDataCollectionRequest} from "../Hooks";


export const SelectMaterias = ({ onChange = () => { }, defaultValue, name = '', matterDisable }) => {

    const { dataCollectionRequest: getMaterias } = useDataCollectionRequest('obtener_materias', 'all');

        return (
            <select
                className="form-select" id="floatingSelect"
                aria-label="Floating label select example"
                onChange={ (e) => {
                    onChange(e);
                } }
                name={name}
                required
                disabled={matterDisable}
                defaultValue={defaultValue}
            >
                <option selected>Escoge la materia</option>
                {
                    getMaterias &&
                    getMaterias.map((matter, index) => {

                        return (
                            <option
                                selected = {defaultValue == matter?.tipo_juicio}
                                key={index}
                                value={ JSON.stringify(matter) }
                            >
                                {matter.tipo_juicio}
                            </option>
                        )
                    })
                }
            </select>
        )
}