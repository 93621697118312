import {useDataCollectionRequest} from "../Hooks";

export const SelectCiudades = ({id_estado, onChange = ()=>{}, defaultValue, name = '', disabled = false }) => {

    const { dataCollectionRequest : getCiudadesMunicipios } = useDataCollectionRequest(
        'obtener_ciudades_municipios_por_estado&id_estado='+id_estado,
        'all'
    );

    return (
        <select
            className='form-select'
            name={name}
            onChange={ (e) => {
                onChange(e);
            } }
            disabled={disabled}
        >
            <option value={''}>Selecciona una ciudad</option>
            {
                getCiudadesMunicipios?.map((ciudad) => (
                    <option key={ciudad.id} value={ciudad.id} selected={ciudad.id == defaultValue} >
                        { ciudad.nombre }
                    </option>
                ))
            }
        </select>
    )
}