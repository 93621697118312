
import {AiOutlineMail, AiOutlineMessage} from 'react-icons/ai';
import {BsFacebook} from 'react-icons/bs';
import logoSHyA from '../../Assets/img/Abogado_azuLOsLetra2a.png';


export const Footer = () => {
  return (
    <div className="container-fluid">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
            <img src={logoSHyA} alt="" width={100} className="me-2" />
          </a>
          <span className="text-muted"> © 2022 Abogado </span>
        </div>

        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3"> <AiOutlineMail className="fs-3" /> </li>
          <li className="ms-3"> <BsFacebook className="fs-3"/> </li>
          <li className="ms-3"> <AiOutlineMessage className="fs-3"/> </li>
        </ul>
      </footer>
    </div>
  )
}
