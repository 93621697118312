import React, {useState, useCallback, useMemo, useRef} from 'react'
import { SearchDataToTable } from '../../../Components/SearchDataToTable'
import { useDataCollectionRequest } from '../../../Hooks';
import { AgGridReact } from 'ag-grid-react';
import { BsTrash } from 'react-icons/bs';
import { requestPost } from '../../../Helpers';
import { alertDialog, alertToast } from '../../../Helpers/Alerts';
import {SelectCiudades} from "../../../Components/SelectCiudades";
import {SelectEstados} from "../../../Components/SelectEstados";
import {FiEdit, FiSave} from "react-icons/fi";
import {HiViewGridAdd} from "react-icons/hi";

export const RegisterJuzgados = () => {
  
    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getJuzgados, setDataCollectionRequest : setJuzgados } = useDataCollectionRequest(
        'obtener_juzgados',
        'all',
        dataUpdate
    );

    const { dataCollectionRequest: getMaterias } = useDataCollectionRequest(
        'obtener_materias',
        'all'
    );

    const { dataCollectionRequest: getCiudadesMunicipios } = useDataCollectionRequest(
        'obtener_ciudades_municipios',
        'all'
    );

    const { dataCollectionRequest: getEstados } = useDataCollectionRequest(
        'obtener_estados',
        'all'
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterJuzgados = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const handleAddJuzgado = useCallback((data) => {

        const formData = new FormData();
        formData.append('juzgado_info', JSON.stringify(data));

        requestPost('agregar_juzgado', formData)
            .then((resp) => {
                if (resp > 0) {
                    // asignar el id al objeto que se agrego a la tabla para poder actualizarlo despues de agregarlo a la base de datos
                    data.id = resp;
                    // show a toast to alert the user that the matter was added
                    alertToast('El juzgado fue agregado correctamente', 'success');
                    return resp;
                } else {
                    alertToast('No se pudo agregar el juzgado', 'error');
                }
            })


    }, []);

    const handleUpdateJuzgado = useCallback((data) => {

        const formData = new FormData();
        formData.append('juzgado_info', JSON.stringify(data));

        requestPost('actualizar_juzgado', formData)
            .then((resp) => {
                if (resp == 1) {
                    // show a toast to alert the user that the matter was added
                    alertToast('El juzgado fue actualizado correctamente', 'success');
                    return resp;
                } else {
                    alertToast('No se pudo actualizar el juzgado', 'error');
                }
            })
        

    }, []);

    const handleDeleteJuzgado = useCallback((id_juzgado) => {
        
        const formData = new FormData();
        formData.append('juzgado_id', id_juzgado);

        requestPost('eliminar_juzgado', formData)
            .then((resp) => {
                if (resp == 1) {
                    // show a toast to alert the user that the matter was added
                    alertToast('El juzgado fue eliminado correctamente', 'success');
                    setDataUpdate(!dataUpdate);
                    return resp;
                } else {
                    alertToast('No se pudo eliminar el juzgado', 'error');
                }
            } )
        
    }, [dataUpdate]);

    const addItemToTable = useCallback(() => {
        
        // get the number of rows in the table
        const rowCount = gridRef.current.api.getDisplayedRowCount();

        const newItem = [
            {
                id: null,
                nombre: `Juzgado ${rowCount + 1}`,
                direccion: '',
                id_materia: '',
                id_ciudad_municipio: '',
                id_estado: '',
            }
        ]

        // add the new item to the table
        gridRef.current.api.applyTransaction({ add: newItem });

    }, []);

    const cellValueChanged = useCallback((e) => {
        const {data} = e;

    }, []);

    return (
        <div style={containerStyle}>
            <h3 className='m-3 mb-4 text-center'>Juzgados</h3>

            
            <form className="row mb-3">

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar juzgado: '} onFilter={onFilterJuzgados} />
                </div>
            
                <div className='d-flex justify-content-end '>
                    <button
                        className='btn btn-primary me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItemToTable();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar juzgado
                    </button>
                </div>
            </form>

            <div style={gridStyle} className={'row ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Nombre' },
                        { field: 'direccion', headerName: 'Dirección' },
                        { 
                            field: 'id_materia', 
                            headerName: 'Materia',
                            editable: false,
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <select
                                        className='form-select'
                                        defaultValue={data.id_materia}
                                        onChange={ (e) => {
                                            const {value} = e.target;
                                            // update the row in the table
                                            data.id_materia = value;
                                            params.api.applyTransaction({ update: [data] });
                                        }
                                        }
                                    >
                                        <option value={''}>Selecciona una materia</option>
                                        {
                                            getMaterias?.map((materia) => (
                                                <option key={materia.id} value={materia.id}>{materia.tipo_juicio}</option>
                                            ))
                                        }
                                    </select>
                                )
                            } 
                        },
                        { 
                            field: 'id_estado', 
                            headerName: 'Estado',
                            editable: false,
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <SelectEstados
                                        onChange={(e)=>{
                                            const {value} = e.target;
                                            // update the row in the table
                                            data.id_estado = value;
                                            params.api.applyTransaction({ update: [data] });
                                        }}
                                        defaultValue={data.id_estado}
                                    />
                                )
                            }
                        },
                        { 
                            field: 'id_ciudad_municipio', 
                            headerName: 'Ciudad',
                            editable: false,
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <SelectCiudades
                                        id_estado={data.id_estado}
                                        onChange={ (e)=>{
                                            const {value} = e.target;
                                            // update the row in the table
                                            data.id_ciudad_municipio = value;
                                            params.api.applyTransaction({ update: [data] });
                                        }}
                                        defaultValue={data.id_ciudad_municipio}
                                    />
                                )
                            }
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <>
                                        <div className={'d-flex justify-content-center gap-3'}>

                                            <button
                                                className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                                onClick={ (event) => {
                                                    event.preventDefault()
                                                    data?.id ? handleUpdateJuzgado(data) : handleAddJuzgado(data)
                                                } }
                                            >
                                                {
                                                    data?.id ?
                                                        <FiEdit className={'fs-5 m-auto mx-1'} />
                                                        :
                                                        <FiSave className={'fs-5 m-auto mx-1'} />
                                                }
                                            </button>

                                            <button
                                                className='btn btn-danger w-100'
                                                onClick={ () => {
                                                    console.log(data)
                                                    alertDialog('¿Estas seguro de eliminar este juzgado?', 'Eliminar juzgado', 'Cancelar')
                                                        .then((resp) => {
                                                            if (resp) {
                                                                handleDeleteJuzgado(data.id);
                                                            }
                                                        })
                                                } }
                                            >
                                                <BsTrash className={'fs-5 m-auto mx-1'} />
                                            </button>

                                        </div>
                                    </>
                                )
                            },

                        }
                    ]}
                    rowData={getJuzgados || []}
                    onCellValueChanged={cellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>

            
        </div>
    )
}
