import {useState, useEffect} from 'react'
import { CreateExpediente, ViewExpediente } from './components'
import { useParams } from 'react-router-dom';

export const RegisterFile = () => {

    const { id_matter, abbreviation_matter, chosen_matter, digits, year} = useParams();

    const [activeTabFile, setActiveTabFile] = useState({
        registerFile: true,
        viewFile: false
    })

    return (
        <div className='container-fluid mt-2'>

            <div className="container container-md">

                {
                    <CreateExpediente />
                }

            </div>

        </div>
    )
}
