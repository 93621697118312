import { useReducer } from 'react';
import { types } from '../Types/types';
import { AuthContext } from './AuthContext';
import { authReducer } from './authReducer';

const initialState = {
    logged: false,
}

const init = () => {
    const user = JSON.parse(localStorage.getItem('user_file_control_system'));
    
    return {
        logged: !!user,
        user: user
    }
}

export const AuthProvider = ({children}) => {

    const [authState, dispatch] = useReducer(authReducer, initialState, init);

    const login = ( correo = '', password = '', id, nombres, apellidos, num_celular, rol, id_rol ) => {
        const user = {
            id: id,
            nombres: nombres,
            apellidos: apellidos,
            num_celular: num_celular,
            correo: correo,
            password: password,
            rol: rol,
            id_rol: id_rol
        }

        const action = {
            type: types.login,
            payload: user
        }

        localStorage.setItem('user_file_control_system', JSON.stringify(user));
        dispatch(action);
    }

    const logout = () => {
        localStorage.removeItem('user_file_control_system');
        dispatch({
            type: types.logout
        });
    }

    return (
        <AuthContext.Provider
            value={{
                ...authState,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
