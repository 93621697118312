import React, {useCallback, useMemo, useRef, useState} from "react";
import {useDataCollectionRequest} from "../../../Hooks";
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {AgGridReact} from "ag-grid-react";
import {FiEdit, FiSave} from "react-icons/fi";
import {alertDialog, alertError, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {BsTrash} from "react-icons/bs";
import {SelectEstados} from "../../../Components/SelectEstados";
import {HiViewGridAdd} from "react-icons/hi";
import {requestPost} from "../../../Helpers";


export const RegistrarCiudades = ({idEstado}) => {


    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getCiudades, setDataCollectionRequest : setMunicipios } = useDataCollectionRequest(
        `obtener_ciudades_municipios`,
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterMunicipios = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);


    // add a row to table
    const addItemToTable = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                nombre: `Ciudad ${getRowCount + 1}`,
                id_estado: idEstado
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);

    const onCellValueChanged = useCallback((e) => {

        const { data } = e;


    }, []);

    const handleAddMunicipio = useCallback((data) => {

        const formData = new FormData();
        formData.append('ciudad_municipio_info', JSON.stringify(data));

        requestPost('agregar_ciudad_municipio', formData)
            .then( res => {
                if(res > 0){
                    alertSuccess('Agregado', 'Ciudad o Municipio agregado correctamente');
                    data.id = res;
                    setDataUpdate(!dataUpdate);
                } else {
                    alertError('Error', 'No se pudo agregar la ciudad o municipio');
                }
            })

    } ,[]);

    const handleUpdateMunicipio = useCallback((data) => {

        const formData = new FormData();
        formData.append('ciudad_municipio_info', JSON.stringify(data));

        requestPost('actualizar_ciudad_municipio', formData)
            .then( res => {
                if(res == 1){
                    alertToast( 'Ciudad o Municipio actualizado correctamente', 'success');
                } else {
                    alertError('No se pudo actualizar la ciudad o municipio', 'Error');
                }
            } )

    } ,[]);

    const handleDeleteMunicipio = useCallback((id) => {

        const formData = new FormData();
        formData.append('ciudad_municipio_id', id);

        requestPost('eliminar_ciudad_municipio', formData)
            .then( res => {
                if(res == 1){
                    alertToast( 'Ciudad o Municipio eliminado correctamente', 'success');
                    setDataUpdate(!dataUpdate);
                } else {
                    alertError('No se pudo eliminar la ciudad o municipio', 'Error');
                }
            } )

    },[]);

    return (
        <div className='' style={containerStyle}>

            <h3 className='m-3 mb-4 text-center'> Registrar Ciudades o Municipios </h3>

            <form className='d-flex flex-column row'>

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar Municipio: '} onFilter={onFilterMunicipios} />
                </div>

                <div className='d-flex justify-content-end '>
                    <button
                        className='btn btn-primary w-25 me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItemToTable();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Municipio
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Nombre' },
                        {
                            field: 'id_estado',
                            headerName: 'Estado',
                            editable: false,
                            cellRenderer: (params) => {
                                const {data} = params;

                                return (
                                    <SelectEstados
                                        name={'id_estado'}
                                        defaultValue={data?.id_estado}
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            const {data} = params;
                                            data.id_estado = value;
                                        }}
                                    />
                                )
                            }
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <div className='d-flex justify-content-evenly gap-3'>
                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateMunicipio(data) : handleAddMunicipio(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                alertDialog('¿Estás seguro de eliminar la materia?', 'Eliminar Materia', 'Cancelar')
                                                    .then((resp) => {
                                                        if (resp) {
                                                            handleDeleteMunicipio(data.id);
                                                        }
                                                    })
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getCiudades}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>

            <div className='m-3'>

            </div>

        </div>
    )
}