import React, {useState, useRef, useMemo, useCallback} from 'react'
import {Table} from "../../../Components/Tables/Table";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {alertDialog, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { BsTrash } from 'react-icons/bs';
import {FiEdit, FiSave} from "react-icons/fi";
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {HiViewGridAdd} from "react-icons/hi";

export const ObservationsToExpediente = ( ) => {

    const { expediente_id } = useParams();

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getObservations, setDataCollectionRequest } = useDataCollectionRequest(
        `obtener_observaciones_por_expediente&expediente_id=${expediente_id}`,
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterObservation = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const addRowToTable = useCallback((e) => {

        const getLastRow = gridRef.current.api.getDisplayedRowAtIndex(gridRef.current.api.getDisplayedRowCount() - 1);

        const newItem = [
            {
                id: null,
                observacion: `Observación ${getLastRow.id + 1}`,
                tipo: '',
                autoridad: '',
                fecha: '',
                id_expediente: parseInt(expediente_id),
            }
        ]

        gridRef.current.api.applyTransaction({add: newItem});

    }, [] );

    const handleAddObservation = (event, data) => {

        const formData = new FormData();
        formData.append('observacion_info', JSON.stringify(data));

        requestPost('agregar_observacion_expediente', formData)
            .then((response) => {
                alertSuccess('Exito','Pago agregado correctamente');
                data.id = response;
                setDataUpdate(!dataUpdate);
            } )
            .catch((error) => {
                console.log(error);
            });

    }

    const handleUpdateObservation = (event, data) => {

        const formData = new FormData();
        formData.append('observacion_info', JSON.stringify(data));

        requestPost('actualizar_observacion_expediente', formData)
            .then((response) => {
                if (response == 1 || response == '1') {
                    alertToast('Observacion actualizada correctamente', 'success');
                } else {
                    alertToast('No se pudo actualizar la observacion', 'error');
                }
            } )

    }

    const handleDeleteObservation = (data) => {

        const formData = new FormData();
        formData.append('observacion_id', data.id);

        alertDialog('¿Estas seguro de eliminar esta observacion?', 'Si, eliminar', 'Cancelar')
            .then((response) => {
                if (response) {
                    requestPost('eliminar_obvervacion_expediente', formData)
                    .then((response) => {
                        if (response == 1 || response == '1' || response) {
                            alertToast('Observacion eliminada correctamente', 'success');
                            setDataUpdate(!dataUpdate);
                        } else {
                            alertToast('No se pudo eliminar la observacion', 'error');
                        }
                    })
                }
            });

    }

    return (
        <div style={containerStyle} className={'container container-md mt-4 mb-3 row'}>

            <div className={'row'}>

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar observación: '} onFilter={onFilterObservation} />
                </div>

                <div className={'d-flex justify-content-end mt-2 mb-3'} >
                    <button className={'btn btn-primary'} onClick={addRowToTable}>
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar observación
                    </button>
                </div>

            </div>



            <div style={gridStyle} className={'ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { headerName: 'id', field: 'id', hide: true },
                        {
                            headerName: 'Observaciones',
                            field: 'observacion',
                            editable: true,
                            cellEditor: 'agLargeTextCellEditor',
                            cellEditorPopup: true,
                            cellEditorParams: {
                                rows: 10,
                                cols: 50
                            },
                            flex: 2,
                        },
                        { headerName: 'Tipo', field: 'tipo', editable: true, },
                        { headerName: 'Autoridad', field: 'autoridad', editable: true, },
                        {
                            headerName: 'Fecha',
                            field: 'fecha',
                            cellRenderer: (params) => {
                                const {data} = params;

                                return (
                                    <input
                                        type="date"
                                        className={'form-control'}
                                        defaultValue={data?.fecha}
                                        onChange={(e) => {
                                            data.fecha = e.target.value;
                                            gridRef.current.api.applyTransaction({update: [data]});
                                        } }
                                    />
                                )
                            }
                        },
                        { headerName: 'id_expediente', field: 'id_expediente', hide: true },
                        {
                            field: 'buttons',
                            headerName: 'Acciones',
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <div className={'d-flex justify-content-center gap-3'}>

                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateObservation(event, data) : handleAddObservation(event, data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                console.log(data)
                                                handleDeleteObservation(data)
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                )
                            },

                        }
                    ]}
                    rowData={getObservations || []}
                />
            </div>

        </div>
    )
}