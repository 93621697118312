import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import logoAbogada from "../Assets/img/Abogado_azuLOsLetra2a.png";
import { AuthContext } from "../Auth";
import { useForm } from "../Hooks/useForm";


export const NavBarClient = () => {

  const navigate = useNavigate();

  const onLogin = () => {
  
    navigate('/public/login');

  }


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light top-0 position-sticky" style={{"zIndex":"100"}}>
        <div className="container-fluid">
          <NavLink to="/" className="navbar-brand">
            <img
              className="me-4"
              src={logoAbogada}
              alt="Logo Abogada"
              style={{ width: "90px" }}
            />
          </NavLink>



          <div>
            <button 
              type="button" 
              className="btn btn-outline-primary"
              onClick={onLogin}
            >
              Inciar sesión
            </button>

          </div>
        </div>
      </nav>

      
    </>
  );
};

