import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {alertToast} from "../../../Helpers/Alerts";
import {requestPost} from "../../../Helpers";
import {SelectCiudades} from "../../../Components/SelectCiudades";
import {SelectEstados} from "../../../Components/SelectEstados";
import {SelectJuzgados} from "../../../Components/SelectJuzgados";
import {SelectMaterias} from "../../../Components/SelectMaterias";
import {FiEdit, FiSave} from "react-icons/fi";
import {BsTrash} from "react-icons/bs";
import {useDataCollectionRequest} from "../../../Hooks";
import {useParams} from "react-router-dom";
import {HiViewGridAdd} from "react-icons/hi";


export const ExhortoExpediente = () => {

    const { expediente_id } = useParams();

    const gridRef = useRef();

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const [updateData, setUpdateData] = useState(false);

    const { dataCollectionRequest : getExhortos, setDataCollectionRequest : setExhorto } = useDataCollectionRequest(
        `obtener_exhorto_por_expediente&expediente_id=${expediente_id}`,
        'all',
        updateData
    )

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterExhorto = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const addRowToTable = useCallback((e) => {

        const getRowNode = gridRef.current.api.getSelectedNodes();

        const newItem = [
            {
                id: null,
                num_exhorto: `Num exhorto ${getRowNode.length + 1}`,
                id_juzgado: '',
                id_materia: '',
                id_ciudad_municipio: '',
                id_estado: '',
                id_expediente: parseInt(expediente_id),
            }
        ]

        gridRef.current.api.applyTransaction({ add: newItem });

    }, [] );

    const handleAddExhorto = useCallback((data) => {

        const formData = new FormData();
        formData.append('exhorto_info', JSON.stringify(data));

        requestPost('agregar_exhorto_a_expediente', formData)
            .then ( (res) => {
                if (res >= 1) {
                    alertToast('El exhorto fue agregado correctamente', 'success');
                    setUpdateData(!updateData);
                } else {
                    alertToast('No se pudo agregar el exhorto', 'error');
                }
            } )

    }, []);

    const handleUpdateExhorto = useCallback((data) => {

        const formData = new FormData();
        formData.append('exhorto_info', JSON.stringify(data));

        requestPost('actualizar_exhorto_a_expediente', formData)
            .then ( (res) => {
                if (res == 1) {
                    alertToast('El exhorto fue actualizado correctamente', 'success');
                } else {
                    alertToast('No se pudo actualizar el exhorto', 'error');
                }
            })
    }, []);


    const handleDeleteExhorto = useCallback((data) => {

        const formData = new FormData();
        formData.append('exhorto_id', data.id);

        requestPost('eliminar_exhorto_de_expediente', formData)
            .then ( (res) => {
                if (res == 1) {
                    alertToast('El exhorto fue eliminado correctamente', 'success');
                    setUpdateData(!updateData);
                } else {
                    alertToast('No se pudo eliminar el exhorto', 'error');
                }
            } )
    }, []);

    const onCellValueChanged = useCallback((e) => {

    }, []);

    return (
        <div className=" mt-4" style={containerStyle}>

            <form className={''}>
                <div className={'m-auto w-75 '}>
                    <SearchDataToTable name={'Buscar exhorto'} onFilter={onFilterExhorto} />
                </div>

                <div className={'d-flex justify-content-end mb-3'}>
                    <button
                        className={'btn btn-primary me-4'}
                        onClick={ (e) => {
                            e.preventDefault();
                            addRowToTable();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar exhorto
                    </button>
                </div>

            </form>

            <div style={gridStyle} className={'row ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    rowData={getExhortos || []}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'num_exhorto', headerName: 'Num exhorto' },
                        {
                            field: 'id_materia',
                            headerName: 'Materia',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectMaterias
                                        name={'id_materia'}
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            data.id_materia = JSON.parse(value).id;
                                        } }
                                        defaultValue={data.materia}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_estado',
                            headerName: 'Estado',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectEstados
                                        name={'id_estado'}
                                        onChange={(e) => {
                                            data.id_estado = e.target.value;
                                        }}
                                        defaultValue={data.id_estado}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_ciudad',
                            headerName: 'Ciudad',
                            editable: false,
                            cellRenderer : (params) => {
                                const { data } = params;

                                return (
                                    <SelectCiudades
                                        name={'id_ciudad'}
                                        onChange={(e) => {
                                            data.id_ciudad = e.target.value;
                                        } }
                                        defaultValue={data.id_ciudad}
                                        id_estado={data.id_estado}
                                        disabled={data.id_estado == ''}
                                    />
                                )
                            }
                        },
                        {
                            field: 'id_juzgao',
                            headerName: 'Juzgado',
                            editable: false,
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <SelectJuzgados
                                        id_ciudad={data.id_ciudad}
                                        name={'id_juzgado'}
                                        id_estado={data.id_estado}
                                        id_materia={data.id_materia}
                                        onChange={ (e) => {
                                            data.id_juzgado = e.target.value;
                                        } }
                                        defaultValue={data.id_juzgado}
                                        disabled={data.id_ciudad == '' && data.id_materia == ''}
                                    />
                                )
                            }
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            cellRenderer : (params) => {
                                const {data} = params;

                                return (
                                    <div className={'d-flex justify-content-center gap-3'}>

                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateExhorto(data) : handleAddExhorto(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                console.log(data)
                                                handleDeleteExhorto(data)
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                )
                            }
                        }
                    ] }
                    pagination={true}
                    paginationPageSize={10}
                    onCellValueChanged={onCellValueChanged}
                    rowSelection={'single'}
                />
            </div>
        </div>
    )

}