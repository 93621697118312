import {AiOutlineUserAdd} from "react-icons/ai";
import {useDataCollectionRequest} from "../../../Hooks";

const valuesByDefault = {
    client: '',
    tipo_cliente: '',
}

export const DatosCliente = ( {onInputChange, chosen_matter, defaultValues = valuesByDefault} ) => {

    const { dataCollectionRequest: getClients } = useDataCollectionRequest('obtener_clientes', 'all');

    return (
        <div className={'row'}>

            <h3 className="col-12 text-center text-black-50 mb-2">Cliente:</h3>

            <div className='mb-3 row'>
                <div className='d-flex flex-row gap-2 '>
                    <div className='input-group mb-3'>
                        <label htmlFor='clientSelect' className='input-group-text'> Cliente: </label>
                        <select className='form-select' id='clientSelect' name='client' onChange={onInputChange} defaultValue={defaultValues?.client} >
                            <option disabled selected>Elija un cliente</option>
                            {
                                getClients &&
                                getClients.map((client, index) => (
                                    <option
                                        key={index}
                                        value={client?.id}
                                        selected={defaultValues?.client === client?.id}
                                    >
                                        {client.nombres} {client.apellidos}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <AiOutlineUserAdd className='form-label fs-3 m-auto'/>
                </div>
            </div>

            <div className={'input-group mb-3'}>
                <label htmlFor='tipo_cliente' className='input-group-text'> Tipo de cliente </label>

                <select
                    className='form-select'
                    name={'tipo_cliente'}
                    id={'tipo_cliente'}
                    onChange={onInputChange}
                    disabled={chosen_matter === 'Administrativo'}
                    defaultValue={defaultValues?.tipo_cliente}
                >
                    <option selected>Seleccione una opción</option>
                    {
                        chosen_matter === 'Amparo'
                            ? (
                                ['Quejoso', 'Tercero perjudicable'].map( (tipo, index) => {
                                    return (
                                        <option key={index} value={tipo} selected={defaultValues?.tipo_cliente == tipo }> {tipo} </option>
                                    )
                                } )
                            )
                            : (
                                ['Actor', 'Demandado', 'Promovente'].map((tipo, index) => {
                                    if (chosen_matter === 'Administrativo') {
                                        return (
                                            <option selected value='Promovente'>Promovente</option>
                                        )
                                    }
                                    return (
                                        <option
                                            key={index}
                                            value={tipo}
                                            selected={defaultValues?.tipo_cliente == tipo}
                                        >
                                            {tipo}
                                        </option>
                                    )
                                })
                            )
                    }

                </select>
            </div>

        </div>
    )
}