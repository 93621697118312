import React, {useEffect} from 'react'
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {alertError, alertSuccess} from "../../../Helpers/Alerts";

export const EditClient = () => {

    const {id_cliente} = useParams();

    const { dataCollectionRequest : getClient } = useDataCollectionRequest(
        `obtener_cliente_por_id&id_cliente=${id_cliente}`,
        'row'
    );

    const { formState, onInputChange, onResetForm } = useForm(getClient);


    useEffect(() => {
        onResetForm(getClient);
    } , [getClient]);


    const handleUpdateClient = () => {

        const body = {
            nombres: formState.nombres,
            apellidos: formState.apellidos,
            CURP: formState.CURP,
            num_celular: formState.num_celular,
            num_celular_contacto: formState.num_celular_contacto,
            direccion: formState.direccion,
            rfc: formState.rfc,
            correo: formState.correo,
            id: parseInt(id_cliente),
        }

        console.log(body);

        const formData = new FormData();
        formData.append('client_info', JSON.stringify(body));

        requestPost('actualizar_cliente', formData)
            .then((response) => {
                if (response == 1) {
                    alertSuccess('Exito','Cliente actualizado correctamente');
                } else {
                    alertError('Error','No se pudo actualizar el cliente: ' + response);
                }

            } )
            .catch((error) => {
                console.log(error);
            } );

    }


    return (
        <div className='container container-md container-lg mt-4 mb-3 row'>

            <h3 className="col-12 text-center text-black-50 mt-2 mb-4">Actualizar los datos del cliente:</h3>

            <form className='row'>

                <div className='row'>
                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3 '>
                            <input type='text' defaultValue={getClient?.nombres} className='form-control' onChange={onInputChange} name='nombres' id='firstName' />
                            <label htmlFor='firstName'>Nombre(s): </label>
                        </div>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3'>
                            <input type='text' defaultValue={getClient?.apellidos} className='form-control' onChange={onInputChange} name='apellidos' id='lastName' />
                            <label htmlFor='lastName'>Apellido(s): </label>
                        </div>
                    </div>
                </div>

                <div className='row'>

                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3'>
                            <input type='text' className='form-control' defaultValue={getClient?.CURP} onChange={onInputChange} name='CURP' id='CURP' />
                            <label className='form-label' htmlFor='CURP'>CURP: </label>
                        </div>
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3'>
                            <input type='tel' className='form-control' defaultValue={getClient?.num_celular} onChange={onInputChange} name='num_celular' id='phone' />
                            <label className='form-label' htmlFor='phone'>Telefono: </label>
                        </div>
                    </div>

                </div>

                <div className='row'>

                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3'>
                            <input type='text' defaultValue={getClient?.rfc} onChange={onInputChange} className='form-control' name='rfc' id='rfc' />
                            <label htmlFor='rfc' className='form-label'> RFC: </label>
                        </div>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <div className='form-floating mb-3'>
                            <input type='tel' defaultValue={getClient?.num_celular_contacto} onChange={onInputChange} className='form-control' name='num_celular_contacto' id='contactPhone' />
                            <label htmlFor='contactPhone' className='form-label'> Telefono de contacto: </label>
                        </div>
                    </div>
                </div>

                <div className={''}>
                    <div className='form-floating mb-3'>
                        <input type='email' defaultValue={getClient?.correo} onChange={onInputChange} className='form-control' name='correo' id='email' />
                        <label htmlFor='email' className='form-label'> Email: </label>
                    </div>
                </div>

                <div>
                    <div className='form-floating mb-3'>
                        <input type='text' className='form-control' defaultValue={getClient?.direccion} onChange={onInputChange} name='direccion' id='address' />
                        <label className='form-label' htmlFor='address'>Dirección: </label>
                    </div>
                </div>

                <div className='input-group mb-3'>
                    <label className='input-group-text' htmlFor='INE'> INE </label>
                    <input type='file' className='form-control' name='ine' id='INE' />
                </div>

                <div className='mt-3 d-flex justify-content-end'>
                    <input type='button' value='Guardar' className='btn btn-success w-25' onClick={handleUpdateClient}/>
                </div>

            </form>
        </div>
    )
}
