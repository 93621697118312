import { baseUrl, baseUrlServices } from "../shared/baseUrl"

export const requestPost = async (serviceName, formData) => {
  
    try {

        if (!serviceName || !formData) {
            throw new Error("Service name or form data is missing");
        }

        const bodyRequest = {
            method: "POST",
            body: formData
        }

        const responsePost = await fetch(baseUrlServices.concat(serviceName), bodyRequest);
        const response = responsePost.text();
        if (response !== '0') {
            console.log("Success", response);
            return response;
        } 
        
        return 'Error';
        
    } catch (error) {
        console.log(error);
    }

}
