

export const CausaInvestigacion = ({ onInputChange, formState }) => {


    return (
        <>
            <div className='row'>
                <h2 className='text-center text-black-50 mb-2 '> Causa de investigación </h2>
            
                <div className='row'>

                    <div className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="victima" className="input-group-text">Victima:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'victima'}
                                    defaultValue={formState?.victima}
                                />
                            </div>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="ofendido" className="input-group-text">Ofendido:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'ofendido'}
                                    defaultValue={formState?.ofendido}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="clasificacion" className="input-group-text">Clasificación:</label>
                                <select
                                    className={'form-select'}
                                    onChange={onInputChange}
                                    name={'clasificacion'}
                                    defaultValue={formState?.clasificacion}
                                >
                                    <option value="0">Seleccione una opción</option>
                                    {
                                        ['Imputado', 'Indiciado', 'Inculpado'].map((item, index) => (
                                            <option key={index} value={item} selected={formState?.clasificacion == item}>{item}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="delito" className="input-group-text">Delito:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'delito'}
                                    defaultValue={formState?.delito}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="ministerio_publico" className="input-group-text">Ministerio publico:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'ministerio_publico'}
                                    defaultValue={formState?.ministerio_publico}
                                />
                            </div>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="agente_ministerio_publico" className="input-group-text">Agente del ministerio publico:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'agente_ministerio_publico'}
                                    defaultValue={formState?.agente_ministerio_publico}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="accesor_juridico" className="input-group-text">Accesor juridico:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'accesor_juridico'}
                                    defaultValue={formState?.accesor_juridico}
                                />
                            </div>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="defensor_particular" className="input-group-text">Defensor particular:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'defensor_particular'}
                                    defaultValue={formState?.agente_ministerio_publico}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="defensor_oficio" className="input-group-text">Defensor de oficio:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'defensor_oficio'}
                                    defaultValue={formState?.defensor_oficio}
                                />
                            </div>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <div className={'input-group mb-3'}>
                                <label htmlFor="unidad_atencion" className="input-group-text">Unidad de atención:</label>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={onInputChange}
                                    name={'unidad_atencion'}
                                    placeholder={'Agencia en la que se esta llevando acabo'}
                                    defaultValue={formState?.agente_ministerio_publico}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}