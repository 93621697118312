import { useState, useEffect } from 'react';
import { useForm, useRequestGet, useDataCollectionRequest } from '../../../../Hooks';
import {useNavigate, useParams} from "react-router-dom";
import {AiOutlineUserAdd} from 'react-icons/ai';
import { requestPost } from '../../../../Helpers';
import {alertSuccess} from "../../../../Helpers/Alerts";
import {
    NumExpediente,
    DatosCliente,
    Catalogo,
    ContraParte,
    CausaInvestigacion
} from '../../../Components/PartesExpediente';
import {CausaPenal} from "../../../Components/PartesExpediente/CausaPenal";


export const CreateExpediente = () => {

    const navigate = useNavigate();

    const { id_matter, abbreviation_matter, chosen_matter, digits, year} = useParams();

    const {formState, onInputChange, onResetForm} = useForm({});


    const onSubmit = () => {

        const bodyPost = {
            "num_expediente": abbreviation_matter.concat('-', digits || formState?.numberToFile, '-', year || formState?.yearToFile),
            "tipo_cliente": formState?.typeClient,
            "estado_exp": 'activo',
            "id_cliente": parseInt(formState?.client),
            "id_materia": parseInt(id_matter),
            "id_juzgado": parseInt(formState?.court),
        }

        console.log(bodyPost)

        throw new Error('Error al agregar el expediente');

        const formData = new FormData();
        formData.append('expediente_info', JSON.stringify(bodyPost));

        requestPost('agregar_expediente', formData)
            .then(res => {
                console.log(res);
                if (res != '0') {
                    onResetForm();
                    alertSuccess('Guardado con exito', 'Expediente agregado correctamente');
                } else {
                    console.log('Error al agregar el cliente');
                }
            })
            .catch(err => {
                console.log(err);
            });
    }


    return (
        <div className='container container-md container-lg mt-2'>
            <h2 className='mt-4 mb-4 '>Registrar Expediente</h2>
        
            <div className='row'>
                <form className='row'>

                    <h3 className="col-12 text-center text-black-50 mb-2">Numero de expediente:</h3>

                    <NumExpediente onInputChange={onInputChange} minLengthDigit={4} maxLengthDigit={4} matterDisable={true} matterDefaultValue={chosen_matter} digitDefaultValue={digits}  yearDefaultValue={year} />

                    <DatosCliente onInputChange={onInputChange} chosen_matter={chosen_matter} />

                    {
                            chosen_matter == 'Carpeta de Investigación' ? (
                                <>
                                    <CausaInvestigacion formState={formState} onInputChange={onInputChange} chosen_matter={chosen_matter} />
                                </>
                            )
                            : chosen_matter == 'Causa Penal' ? (
                                <>
                                    <CausaPenal formState={formState} onInputChange={onInputChange} chosen_matter={chosen_matter} />
                                </>
                            ) : (
                                    <>
                                        <Catalogo formState={formState} onInputChange={onInputChange} chosen_matter={chosen_matter} id_matter={id_matter} />

                                        {
                                            /* Si el cliente es actor o demandado entonces */
                                            formState?.tipo_cliente === 'Actor' || formState?.tipo_cliente === 'Demandado' || chosen_matter === 'Administrativo' || chosen_matter === 'Amparo'
                                                ?
                                                <ContraParte formState={formState} onInputChange={onInputChange} tipo_cliente={formState.tipo_cliente} chosen_matter={chosen_matter} />
                                                :
                                                ''
                                        }
                                    </>
                                )

                    }

                    <div className='row d-flex justify-content-end mt-3 mb-5'>
                        <input type='button' value='Guardar' className='btn btn-success w-25' onClick={onSubmit}  />
                    </div>

                </form>
            </div>
        </div>
    )
}
