
import logoLawyer from '../Assets/img/Abogado_azuLOs3.png';
import civilLaw from '../Assets/img/derecho_social.png';
import familyRight from '../Assets/img/derecho_familiar.png';
import commercialLaw from '../Assets/img/derecho_mercantil.png';
import { Testimonials, CardServices, Presentation, Location } from './Components';
import { Footer } from './Components/Footer';
import { NavBarClient } from '../NavBars';

export const HomeClient = () => {
  return (
    <>

      <div className="container container-md">
          
          <Presentation 
              logoLawyer={logoLawyer}
              text_title={'Sánchez Herrera Abogados.'}
              text_body={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. '}
              phone={'tel:+34678567876'}

          />

          <div className='row'>
            <h2 
              className='text-center mt-5 mb-5 col-12'
              style={{"color":"#000080"}}
            > 
              Servicios 
            </h2>

            <div className='row row-cols-3 d-flex gap-3 justify-content-center align-content-center'> 
              
              <CardServices
                  imgCard={commercialLaw}
                  text_body={'Derecho Mercantil'}
              />

              <CardServices
                  imgCard={civilLaw}
                  text_body={'Derecho Civil'}
              />

              <CardServices
                  imgCard={familyRight}
                  text_body={'Derecho Familiar'}
              />

            </div>

          </div>

          <div className='row'>
            <h2 
              className='text-center mt-5 mb-5 col-12'
              style={{"color":"#000080"}}
            >
              Testimoniales
            </h2>
            
            <div className='row row-cols-2 d-flex gap-3 justify-content-center align-content-center'>
              
              <Testimonials 
                  text_title ={'Testimonial'} 
                  text_body={'A well-known quote, contained in a blockquote element.'} 
                  text_footer = {'Someone famous in Source Title'}
              />
              <Testimonials 
                  text_title ={'Testimonial'} 
                  text_body={'A well-known quote, contained in a blockquote element.'} 
                  text_footer = {'Someone famous in Source Title'}
              />

            </div>

          </div>

          <div className='row mb-4'>
            <h2 className='text-center mb-5 mt-5 ' style={{"color":"#000080"}}> ¿Donde puedes encontrarme? </h2>
            <Location />
          </div>
          
      </div>

      <Footer />
    </>
  )
}
