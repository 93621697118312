import {requestPost} from "../../../../Helpers";
import {alertToast} from "../../../../Helpers/Alerts";

export const updateClient = (id, data) => {

    const formData = new FormData();
    formData.append('id_cliente', id);
    formData.append('cliente_info', JSON.stringify(data));

    requestPost('actualizar_cliente', formData)
        .then(res => {
            if (res == 1) {
                alertToast('El cliente fue actualizado correctamente', 'success');
            } else {
                alertToast('No se pudo actualizar el cliente', 'error');
            }
        } );

}