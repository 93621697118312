import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {AgGridReact} from "ag-grid-react";
import {alertDialog, alertError, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {BsTrash} from "react-icons/bs";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {useDataCollectionRequest} from "../../../Hooks";
import {FiEdit, FiSave} from "react-icons/fi";
import {HiViewGridAdd} from "react-icons/hi";
import {requestPost} from "../../../Helpers";

export const RegistrarEstados = () => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getEstados, setDataCollectionRequest : setEstados } = useDataCollectionRequest(
        'obtener_estados',
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterEstados = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);


    // add a row to table
    const addItemToTable = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                nombre: `Estado ${getRowCount + 1}`,
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);

    const onCellValueChanged = useCallback((e) => {

        const { data } = e;


    }, []);

    const handleAddEstado = useCallback((data) => {

        const formData = new FormData();
        formData.append('estado_info', JSON.stringify(data));

        requestPost('agregar_estado', formData)
            .then((response) => {
                if(response > 0){
                    alertSuccess('Exito!', 'Estado agregado correctamente');
                    data.id = response;
                    setDataUpdate(!dataUpdate);
                }else{
                    alertError('Error!', 'No se pudo agregar el estado');
                }
            } )

    } ,[]);

    const handleUpdateEstado = useCallback((data) => {

        const formData = new FormData();
        formData.append('estado_info', JSON.stringify(data));

        requestPost('actualizar_estado', formData)
            .then((response) => {
                if(response > 0){
                    alertToast('Estado actualizado correctamente', 'success');
                }else{
                    alertToast('No se pudo actualizar el estado', 'error');
                }
            } )

    } ,[]);

    const handleDeleteEstado = useCallback((id) => {

        const formData = new FormData();
        formData.append('estado_id', id);

        requestPost('eliminar_estado', formData)
            .then((response) => {
                if(response == 1){
                    alertToast('Estado eliminado correctamente', 'success');
                    setDataUpdate(!dataUpdate);
                }else{
                    alertToast('No se pudo eliminar el estado', 'error');
                }
            } )

    },[]);

    return (
        <div className='' style={containerStyle}>

            <h3 className='m-3 mb-4 text-center'> Registrar Estados </h3>

            <form className='d-flex flex-column row'>

                <div className={'m-auto w-75'}>
                    <SearchDataToTable name={'Buscar Estado: '} onFilter={onFilterEstados} />
                </div>

                <div className='d-flex justify-content-end '>
                    <button
                        className='btn btn-primary w-25 me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItemToTable();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Estado
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Nombre' },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <div className='d-flex justify-content-evenly gap-3'>
                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateEstado(data) : handleAddEstado(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                alertDialog('¿Estás seguro de eliminar la materia?', 'Eliminar Materia', 'Cancelar')
                                                    .then((resp) => {
                                                        if (resp) {
                                                            handleDeleteEstado(data.id);
                                                        }
                                                    })
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getEstados}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>

            <div className='m-3'>

            </div>

        </div>
    )
}