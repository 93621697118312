import {requestPost} from "../../../../Helpers";
import {alertSuccess} from "../../../../Helpers/Alerts";

export const addClient = (client) => {

    const formData = new FormData();
    formData.append('client_info', JSON.stringify(client));

    requestPost('agregar_cliente', formData)
        .then(res => {
            console.log(res);
            if (res != '0') {
                alertSuccess('Guardado con exito', 'Cliente agregado correctamente');
            } else {
                console.log('Error al agregar el cliente');
            }
        } )

}