

import React, { useRef } from 'react';
import { SearchDataToTable } from '../../../Components/SearchDataToTable';
import { AgGridReact } from 'ag-grid-react';
import { useCallback } from 'react';
import { useDataCollectionRequest } from '../../../Hooks';
import { useMemo } from 'react';
import { useState } from 'react';
import { alertDialog, alertToast } from '../../../Helpers/Alerts';
import { requestGet, requestPost } from '../../../Helpers';
import {TipoJuicioPorMateria} from "./TipoJuicioPorMateria";
import {FiEdit, FiSave} from "react-icons/fi";
import {BsTrash} from "react-icons/bs";
import {HiViewGridAdd} from "react-icons/hi";
import {SelectMaterias} from "../../../Components/SelectMaterias";

export const RegistrarAcciones = () => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getAcciones, setDataCollectionRequest : setAcciones } = useDataCollectionRequest(
        'obtener_acciones',
        'all',
        dataUpdate
    );


    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterTipoJuicio = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);


       // add a row to table
       const addItems = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                nombre: `Acción ${getRowCount + 1}`,
                id_tipo_juicio: '',
                id_materia: '',
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);

    const onCellValueChanged = useCallback((e) => {
        const { data } = e;

        console.log(data);
        
    }, []);

    const handleAddAcciones = useCallback((data) => {

        const formData = new FormData();
        formData.append('accion_info', JSON.stringify(data));

        requestPost('agregar_accion', formData)
            .then((resp) => {
                if (resp > 0) {
                    // asignar el id al objeto que se agrego a la tabla para poder actualizarlo despues de agregarlo a la base de datos
                    data.id = resp;
                    // show a toast to alert the user that the matter was added
                    alertToast('La acción fue agregada correctamente', 'success');
                    setDataUpdate(!dataUpdate)
                    return resp;
                } else {
                    alertToast('No se pudo agregar la acción', 'error');
                    return null;
                }
            })
    }, []);

    const handleUpdateAcciones = useCallback((data) => {

        const formData = new FormData();
        formData.append('accion_info', JSON.stringify(data));

        requestPost('actualizar_accion', formData)
            .then((resp) => {
                if (resp == 1) {
                    // show a toast to alert the user that the matter was added
                    alertToast('La acción fue actualizada correctamente', 'success');
                    return resp;
                } else {
                    alertToast('No se pudo actualizar la acción', 'error');
                    return null;
                }
            })
    }, []);


    const handleDeleteAcciones = useCallback((id_accion) => {
  
        const formData = new FormData();
        formData.append('accion_id', id_accion);

        requestPost('eliminar_accion', formData)
            .then((resp) => {
                if (resp == 1) {
                    // show a toast to alert the user that the matter was added
                    alertToast('La acción fue eliminada correctamente', 'success');
                    setDataUpdate(!dataUpdate)
                    return resp;
                } else {
                    alertToast('No se pudo eliminar la acción', 'error');
                    return null;
                }
            })

    }, []);


    return (
        <div className='' style={containerStyle}>
            <h3 className='m-3 mb-4 text-center'>Acciones</h3>

            <form className='d-flex flex-column row'>

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar acción: '} onFilter={onFilterTipoJuicio} />
                </div>

                <div className='d-flex justify-content-end '>
                    <button 
                        className='btn btn-primary w-25 me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItems();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar acción
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Acción' },
                        { 
                            field: 'id_materia', 
                            headerName: 'Materia',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <div className='d-flex justify-content-evenly'>
                                        <SelectMaterias
                                            name={'id_materia'}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                data.id_materia = JSON.parse(value)?.id;
                                                params.api.applyTransaction({ update: [data] });
                                            } }
                                            defaultValue={data?.materia}
                                        />
                                    </div>
                                );
                            } 
                        },
                        {
                            field: 'id_tipo_juicio',
                            headerName: 'Tipo de juicio',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <TipoJuicioPorMateria params={params} />
                                );
                            },
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;
                                
                                return (
                                    <div className='d-flex justify-content-evenly gap-3'>
                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateAcciones(data) : handleAddAcciones(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                alertDialog('¿Estás seguro de eliminar la materia?', 'Eliminar Materia', 'Cancelar')
                                                    .then((resp) => {
                                                        if (resp) {
                                                            handleDeleteAcciones(data.id);
                                                        }
                                                    })
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getAcciones}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>
            
        </div>
    )
}
