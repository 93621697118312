import {TableClients} from "../../FileControl/Components/Clients/Components/TableClients";

export const Clientes = () => {

    return (
        <div className={'container '}>

            <h3 className={'text-center m-3'}> Administrar los clientes </h3>

            <TableClients
                hideBtn={{
                    delete: true,
                    update: true,
                    add: true,
                } }
            />

        </div>
    )

}