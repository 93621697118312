import {CausaInvestigacion} from "./CausaInvestigacion";


export const CausaPenal = ({ onInputChange, formState }) => {


    return (
        <div className={'row'}>

            <CausaInvestigacion />

            <h3 className={'col-12 text-center text-black-50 mb-2'}> Causa Penal </h3>

            <div className='row'>
                <div className="mb-3 col-12 col-md-6">
                    <div className={'input-group mb-3'}>
                        <label htmlFor="juez_control" className="input-group-text">Juez de control:</label>
                        <input
                            type={'text'}
                            className={'form-control'}
                            onChange={onInputChange}
                            name={'juez_control'}
                            defaultValue={formState?.juez_control}
                        />
                    </div>
                </div>

                <div className="mb-3 col-12 col-md-6">
                    <div className={'input-group mb-3'}>
                        <label htmlFor="numero_causa_penal" className="input-group-text">Numero de la causa penal:</label>
                        <input
                            type={'text'}
                            className={'form-control'}
                            onChange={onInputChange}
                            name={'numero_causa_penal'}
                            defaultValue={formState?.numero_causa_penal}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}