import React from 'react'

export const Location = () => {
  return (
    <div className='row'>
        <iframe 
          src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.30960375793993!2d-101.34757289265976!3d20.671464200000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c7fd4946971a3%3A0x565ce4481fe5dfce!2sVac%20Nor!5e0!3m2!1sen!2smx!4v1655879928161!5m2!1sen!2smx"} 
          width="600" 
          height="450" 
          style={{"border":"0"}}  
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        />
    </div>
  )
}
