import {useDataCollectionRequest} from "../Hooks";


export const SelectTipoJuicio = ({ id_materia, onChange = () => { }, defaultValue = '', name = '', disabled = false }) => {

    const { dataCollectionRequest: getTipoJuicioSegunMateria } = useDataCollectionRequest(
        `obtener_tipo_juicio_segun_materia&materia_id=${parseInt(id_materia)}`,
        'all'
    );

    return (
        <select
            className={'form-select'}
            id={name}
            name={name}
            disabled={disabled}
            onChange={onChange}
        >
            <option>Escoje una opción</option>
            {
                getTipoJuicioSegunMateria &&
                getTipoJuicioSegunMateria.map((tipoJuicio, index) => (
                    <option
                        key={index}
                        value={tipoJuicio.id}
                        selected={tipoJuicio.id == defaultValue}
                    >
                        {tipoJuicio.tipo_juicio}
                    </option>
                ))
            }
        </select>
    )

}