
export const ModalTable = ( { nameButton = '', data, nameClassOpenModalButton = '', titleModal = '', componentBodyModal, buttonName = '', handleButton = () => {} } ) => {

    return (
        <>
            <button className={"btn ".concat(nameClassOpenModalButton)} data-bs-toggle="modal" data-bs-target="#exampleModal" >
                {nameButton || 'Open'}
            </button>

            <div className="modal  fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                {titleModal || ''}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                componentBodyModal
                                ||
                                <p> </p>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleButton}>
                                {buttonName}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}