import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth";

import logoAbogada from "../Assets/img/Abogado_azuLOsLetra2a.png";

import {FaRegUser} from 'react-icons/fa';

export const NavBarAdmin = () => {

  const {user, logout} = useContext(AuthContext);

  const navigate = useNavigate();

  const onLogout = () => {

    logout();

    navigate("/public/home", { replace: true });
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light top-0 position-sticky"
        style={{ "zIndex": "100" }}
      >
        <div className="container-fluid">
          <NavLink to="/" className="navbar-brand">
            <img
              className="me-4"
              src={logoAbogada}
              alt="Logo Abogada"
              style={{ width: "90px" }}
            />
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            {/* Navigation */}
            <ul
              className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
              style={{ "--bs-scroll-height": "100px;" }}
            >
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link" aria-current="page">
                  Inicio
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  to="/about"
                  className="nav-link dropdown-toggle"
                  id="navbarScrollingDropdownRegister"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Registrar
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdownRegister"
                >
                  <li>
                    <NavLink to="/chooseTypeFileToRegister" className="dropdown-item">
                      Expediente
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/registerClient" className="dropdown-item">
                      Cliente
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/about"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Control
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <NavLink to="/listFiles" className="dropdown-item">
                      Expediente
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/about"
                  id="navbarScrollingDropdownQueries"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Agenda
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdownQueries"
                >
                  <li>
                    <NavLink
                      to="/consultation/byCourts"
                      className="dropdown-item"
                    >
                      Por Juzgado
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/consultation/byClients"
                      className="dropdown-item"
                    >
                      Por Cliente
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/consultation/byJudgeAndClerk"
                      className="dropdown-item"
                    >
                      Por Juez y secretario
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/consultation/byLeagalRepresentative"
                      className="dropdown-item"
                    >
                      Por Mandatario judicial
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item ">
                <NavLink
                  className="nav-link"
                  to="/admin"
                  id="navbarScrollingDropdown"
                >
                  Administración
                </NavLink>
              </li>
            </ul>

            {/* Notification */}
            {/* <Notification /> */}

            {/*  The user  */}
            <div className="d-flex  ">
              <div className="dropdown text-start me-5 d-flex align-content-center justify-content-center ">
                <NavLink
                  to="/about"
                  className="d-block link-dark text-decoration-none dropdown-toggle"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >

                  <FaRegUser 
                    className="m-auto mt-2"
                    style={{ fontSize: "1.5rem" }}
                  />

                  {/* <img
                    src="https://github.com/mdo.png"
                    alt="mdo"
                    width="32"
                    height="32"
                    className="rounded-circle"
                  /> */}
                </NavLink>
                <h5 className="mx-2 my-2 me-5"> {user?.nombres} </h5>
                <ul
                  className="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                >
                  <li>
                    <NavLink className="dropdown-item" to="/profile/settings">
                      Configuración
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/profile/view">
                      Ir a mi perfil
                    </NavLink>
                  </li>
                 
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/" onClick={onLogout}>
                      Salir
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
