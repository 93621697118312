import React, { useCallback, useMemo, useRef, useState } from 'react';
import {BsTrash} from 'react-icons/bs';
import {Table} from "../../Components/Tables/Table";
import {useNavigate} from "react-router-dom";
import {useDataCollectionRequest} from "../../Hooks";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import { SearchDataToTable } from '../../Components/SearchDataToTable';


export const ConsultationByCourts = () => {

    const navigate = useNavigate();

    const gridRef = useRef();

    const { dataCollectionRequest: getCourts } = useDataCollectionRequest('obtener_juzgados', 'all');
    
    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterCourt = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);
   
    return (

        <div style={containerStyle} className="container container-md">
            <h2 className="text-center m-3">Consulta por juzgados</h2>

                <form className="row row-cols-2 mb-3">
                
                    <SearchDataToTable name={'Buscar juzgado: '} onFilter={onFilterCourt} />

                </form>

            <div style={gridStyle} className={'row ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Nombre' },
                        { field: 'direccion', headerName: 'Dirección' },
                        { field: 'materia', headerName: 'Materia' },
                        { field: 'ciudad_municipio', headerName: 'Ciudad' },
                        { field: 'estado', headerName: 'Estado' },
                    ]}
                    rowData={getCourts || []}
                />
            </div>

        </div>

    );
}
