import React from 'react'

export const DocumentDeliveryAlert = () => {
    


    return (
        <div className="alert alert-success" role="alert">
            Alerta de entrega de documentos
        </div>
    )
}
