

import React, {useState, useCallback, useRef, useMemo} from 'react'
import { AgGridReact } from 'ag-grid-react';
import { useDataCollectionRequest } from '../../Hooks';
import { SearchDataToTable } from '../../Components/SearchDataToTable';
import {alertDialog, alertError, alertSuccess, alertToast} from '../../Helpers/Alerts';
import {HiViewGridAdd} from "react-icons/hi";
import {FiEdit, FiSave} from "react-icons/fi";
import {BsTrash} from "react-icons/bs";
import {requestPost} from "../../Helpers";
import {SelectMaterias} from "../../Components/SelectMaterias";

export const RegisterTipoJuicio = () => {


    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getTipoJuicio, setDataCollectionRequest : setTipoJuicio } = useDataCollectionRequest(
        'obtener_todos_tipos_juicio',
        'all',
        dataUpdate
    );

    const { dataCollectionRequest : getMatter, setDataCollectionRequest : setMatter } = useDataCollectionRequest(
        'obtener_materias',
        'all',
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterTipoJuicio = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);


       // add a row to table
       const addItems = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                tipo_juicio: `Tipo de juicio ${getRowCount + 1}`,
                id_materia: '',
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);

    const onCellValueChanged = useCallback((e) => {
        const { id, tipo_juicio, abreviatura } = e.data;
        
    }, []);

    const handleAddTipoJuicio = useCallback((data) => {

        const formData = new FormData();
        formData.append('tipo_juicio_info', JSON.stringify(data));

        requestPost('agregar_tipo_juicio', formData)
            .then( res => {
                if(res > 0){
                    alertSuccess('Agregado', 'Tipo de juicio agregado correctamente');
                    data.id = res;
                    setDataUpdate(!dataUpdate);
                }else{
                    alertError('Error', 'No se pudo agregar el tipo de juicio');
                }
            } )

    } ,[]);

    const handleUpdateTipoJuicio = useCallback((data) => {

        const formData = new FormData();
        formData.append('tipo_juicio_info', JSON.stringify(data));

        requestPost('actualizar_tipo_juicio', formData)
            .then( res => {
                if (res == 1) {
                    alertToast('Tipo de juicio actualizado correctamente', 'success');
                } else {
                    alertToast('No se pudo actualizar el tipo de juicio', 'error');
                }
            } )

    } ,[]);

    const handleDeleteTipoJuicio = useCallback((id) => {

        const formData = new FormData();
        formData.append('tipo_juicio_id', id);

        requestPost('eliminar_tipo_juicio', formData)
            .then( res => {
                if (res == 1) {
                    alertToast('Tipo de juicio eliminado correctamente', 'success');
                    setDataUpdate(!dataUpdate);
                } else {
                    alertToast('No se pudo eliminar el tipo de juicio', 'error');
                }
            } )

    } ,[]);
  
    return (
        <div className='' style={containerStyle}>
            <h3 className='m-3 mb-4 text-center'>Tipo de juicio</h3>

            <form className='d-flex flex-column row'>

                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar Tipo de juicio: '} onFilter={onFilterTipoJuicio} />
                </div>
        
                <div className='d-flex justify-content-end '>
                    <button 
                        className='btn btn-primary w-25 me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItems();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Tipo de juicio
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'tipo_juicio', headerName: 'Tipo de Juicio' },
                        { 
                            field: 'id_materia', 
                            headerName: 'Materia',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <SelectMaterias
                                        name={'id_materia'}
                                        defaultValue={data?.materia}
                                        onChange={(e) => {
                                            data.id_materia = JSON.parse(e.target.value)?.id;
                                        }}
                                    />
                                );
                            } 
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;
                                
                                return (
                                    <div className='d-flex justify-content-evenly gap-3'>
                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdateTipoJuicio(data) : handleAddTipoJuicio(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                alertDialog('¿Estás seguro de eliminar el tipo de juicio?', 'Eliminar', 'Cancelar')
                                                    .then((resp) => {
                                                        if (resp) {
                                                            handleDeleteTipoJuicio(data.id);
                                                        }
                                                    })
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getTipoJuicio}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>
            
        </div>
    )
}
