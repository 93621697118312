import React, {useState, useCallback, useMemo, useRef} from 'react'
import {Table} from "../../../Components/Tables/Table";
import {useDataCollectionRequest, useForm} from "../../../Hooks";
import {useParams} from "react-router-dom";
import {requestPost} from "../../../Helpers";
import {alertDialog, alertError, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {TableEdit} from "../../../Components/Tables/TableEdit";
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import {BsPencil, BsTrash} from 'react-icons/bs';
import {FiEdit, FiSave} from 'react-icons/fi';
import {HiViewGridAdd} from 'react-icons/hi';
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import data from "bootstrap/js/src/dom/data";


export const ToDo = () => {

  const { expediente_id } = useParams();

  const gridRef = useRef();
  
  const [updateData, setUpdateData] = useState(false);

  const { formState, onResetForm, onInputChange  } = useForm({});

  const { dataCollectionRequest : getToDo, setDataCollectionRequest } = useDataCollectionRequest(
      `obtener_tareas_por_expediente&expediente_id=${expediente_id}`,
      'all',
      updateData
  );

  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
  const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

  const defaultColDef = useMemo(()=> {
      return {
          flex: 1,
          minWidth: 100,
          width: 150,
          resizable: true,
          sortable: true,
          //floatingFilter: true,
      }
  },[])

  const onGridReady = useCallback(()=> {

  },[])

  const onFilterToDo = useCallback((e) => {
      gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const handleAddToDo = (rowData) => {

    console.log('data', rowData);

    const formData = new FormData();
    formData.append('tarea_info', JSON.stringify(rowData));

    requestPost('agregar_tarea', formData)
        .then((response) => {
            console.log(response);
            if (response > 0) {
                alertSuccess('Exito','Pago agregado correctamente');
                rowData.id = response;
                setUpdateData(!updateData);
            }
        } )
        .catch((error) => {
          console.log(error);
        });

  }

  const handleDeleteToDo = (dataRow) => {

    const handleDelete = () => {

        const formData = new FormData();
        formData.append('tarea_id', parseInt(dataRow?.id) );

        requestPost('eliminar_tarea', formData)
            .then((response) => {
                if (response >= 0) {
                    alertSuccess('Exito','Se ha eliminado correctamente');
                    setDataCollectionRequest(getToDo.filter((todo) => todo.id !== dataRow.id));
                } else {
                    alertError('Error','No se ha podido eliminar la tarea');
                }
            } )
            .catch((error) => {
                console.log(error);
            } );
    }

    alertDialog('¿Estas seguro de eliminar esta tarea?', 'Si, eliminar', 'No, cancelar')
        .then((result) => {
            if (result) {
              handleDelete();
            }
        }
    );

  }

  const handleUpdateToDo = (e, dataRow) => {

      console.log("el body es -> ", dataRow);

      const formData = new FormData();
      formData.append('tarea_info', JSON.stringify(dataRow));

      requestPost('actualizar_tarea', formData)
            .then((response) => {
                console.log(response)
                if (response >= 0) {
                    alertToast('Actualizado correctamente', 'success', 2000);
                } else {
                    alertToast('Error al actualizar', 'error', 3000);
                }
            } )
            .catch((error) => {
                console.log(error);
            } );

  }

  const addRowToTable = useCallback((e) => {

      const newItem = [
          {
                id: null,
                titulo: 'Titulo',
                descripcion: '',
                fecha: '',
                nivel_importancia: '',
                estado: '',
                id_expediente: parseInt(expediente_id),
          }
      ]

      gridRef.current.api.applyTransaction({ add: newItem });

  }, []);

  const onCellValueChanged = useCallback((e) => {

      const {data} = e;

      console.log('onCellValueChanged', data);

  }, []);

  return (
    <div style={containerStyle} className={'container container-md mt-4 mb-3 row'}>

      <div className={'row'}>

          <div className={'w-75 m-auto'}>
              <SearchDataToTable name={'Buscar tarea:'} onFilter={onFilterToDo} />
          </div>

        <div className={'d-flex justify-content-end'} >
            <button className={'btn btn-primary'} onClick={addRowToTable}>
                <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar tarea
            </button>
        </div>

      </div>

      <hr className='mt-3 mb-3' />

      <div style={gridStyle} className={'ag-theme-alpine'}>
        <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            columnDefs={[
              { headerName: 'id', field: 'id', hide: true },
              { headerName: 'Titulo', field: 'titulo', editable: true,  },
              {
                  headerName: 'Descripción',
                  field: 'descripcion',
                  editable: true,
                  cellEditor: 'agLargeTextCellEditor',
                  cellEditorPopup: true,
                  cellEditorParams: {
                      rows: 10,
                      cols: 50
                  },
                  flex: 2,
              },
              {
                  headerName: 'Fecha',
                  field: 'fecha',
                  cellRenderer: (params) => {
                    const {data} = params;

                    return (
                      <input 
                        type='date' 
                        name='fecha'
                        onChange={ (e) => {
                            data.fecha = e.target.value;
                        } }
                        className='form-control'
                        defaultValue={data?.fecha}
                      />
                    )
                  }
              },
              {
                  headerName: 'Importancia',
                  field: 'nivel_importancia',
                  cellRenderer: (params) => {
                    const {data} = params;

                    return (
                      <select
                        className="form-select w-100 me-0 mx-0" id="floatingSelect"
                        aria-label="Floating label select example"
                        name='nivel_importancia'
                        onChange={ (e) => {
                            data.nivel_importancia = e.target.value;
                        } }
                      >
                        <option value=''>Seleccione una opción</option>
                        {
                          ['Bajo', 'Medio', 'Alto'].map ( ( categoria) => {
                            return (
                              <option value={categoria} selected={categoria == data.nivel_importancia} > {categoria}   </option>
                            )
                          } )
                        }
                      </select>
                    )

                  },
              },
              {
                  headerName: 'Estado',
                  field: 'estado',
                  cellRenderer: (params) => {
                    const {data} = params;

                    return (
                      <select
                        className="form-select w-100 me-0 mx-0" id="floatingSelect"
                        aria-label="Floating label select example"
                        name='estado'
                        onChange={ (e) => {
                            data.estado = e.target.value;
                        } }
                      >
                          <option value='' disabled selected>Seleccione una opción</option>
                        {
                          ['Sin terminar', 'En proceso', 'Terminado'].map ( ( status) => {

                            return (
                              <option value={status} selected={status == data.estado} > {status} </option>
                            )
                          } )
                        }
                      </select>
                    )

                  },
              },
              { headerName: 'Id Expediente', field: 'id_expediente', hide: true },
              {
                field: 'acciones',
                headerName: 'Acciones',
                flex: 1,
                cellRenderer: (params) => {
                    const {data} = params;
                    return (
                        <div className={'d-flex justify-content-center gap-3'}>

                            <button
                                className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                onClick={ (event) => {
                                    event.preventDefault()
                                    data?.id ? handleUpdateToDo(event, data) : handleAddToDo(data)
                                } }
                            >
                                {
                                    data?.id ?
                                        <FiEdit className={'fs-5 m-auto mx-1'} />
                                        :
                                        <FiSave className={'fs-5 m-auto mx-1'} />
                                }
                            </button>

                            <button
                                className='btn btn-danger w-100'
                                onClick={ () => {
                                    console.log(data)
                                    handleDeleteToDo(data)
                                } }
                            >
                                <BsTrash className={'fs-5 m-auto mx-1'} />
                            </button>

                        </div>
                    )
                },

              }
            ]}
            rowData={getToDo || []}
        />

      </div>

    </div>
  )
}
