import {useDataCollectionRequest} from "../../../Hooks";

export const TipoJuicioPorMateria = ({params}) => {

    const { dataCollectionRequest : getTipoJuicioByMatter, setDataCollectionRequest } = useDataCollectionRequest(
        `obtener_tipo_juicio_segun_materia&materia_id=${params.data?.id_materia}`,
        'all'
    );

    return (
        <div className='d-flex justify-content-evenly'>
            <select
                className='form-select'
                name='tipo_juicio'
                defaultValue={params.data.id_tipo_juicio}
                onChange={(e) => {
                    const { value } = e.target;
                    params.data.id_tipo_juicio = value;
                    params.api.applyTransaction({ update: [params.data] });
                }
                }
            >
                <option value=''>Selecciona un tipo de juicio</option>
                {
                    getTipoJuicioByMatter &&
                    getTipoJuicioByMatter.map((item) => (
                        <option key={item.id} value={item.id} selected={item.id == params.data.id_tipo_juicio} >
                            {item.tipo_juicio}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}