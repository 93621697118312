import React, {useCallback, useMemo, useRef, useState} from "react";
import {useDataCollectionRequest} from "../../../Hooks";
import {SearchDataToTable} from "../../../Components/SearchDataToTable";
import {AgGridReact} from "ag-grid-react";
import {FiEdit, FiSave} from "react-icons/fi";
import {alertDialog, alertError, alertSuccess, alertToast} from "../../../Helpers/Alerts";
import {BsTrash} from "react-icons/bs";
import {SelectCiudades} from "../../../Components/SelectCiudades";
import {SelectEstados} from "../../../Components/SelectEstados";
import {HiViewGridAdd} from "react-icons/hi";
import {requestPost} from "../../../Helpers";


export const RegistrarPoblados = ({idCiudad, idEstado}) => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getPoblados, setDataCollectionRequest : setPoblados } = useDataCollectionRequest(
        `obtener_poblados`,
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterPoblados = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);


    // add a row to table
    const addItemToTable = useCallback(() => {

        // get the number of rows
        const getRowCount = gridRef.current.api.getDisplayedRowCount();

        // structure to create a new row
        const newItems = [
            {
                id: null,
                nombre: `Poblado ${getRowCount + 1}`,
                id_ciudad_municipio: idCiudad,
                id_estado: idEstado,
            }
        ];

        // add the new row to the table
        gridRef.current.api.applyTransaction({
            add: newItems,
        });

    }, []);

    const onCellValueChanged = useCallback((e) => {

        const { data } = e;


    }, []);

    const handleAddPoblado = useCallback((data) => {

        const formData = new FormData();
        formData.append('poblado_info', JSON.stringify(data));

        requestPost('agregar_poblado', formData)
            .then( res => {
                if(res > 0){
                    alertSuccess('Exito', 'Poblado Agregado', 'El poblado se ha agregado correctamente');
                    data.id = res;
                    setDataUpdate(!dataUpdate);
                } else {
                    alertError('Error', 'Ha ocurrido un error al agregar el poblado');
                }
            } )

    } ,[]);

    const handleUpdatePoblado = useCallback((data) => {

        const formData = new FormData();
        formData.append('poblado_info', JSON.stringify(data));

        requestPost('actualizar_poblado', formData)
            .then( res => {
                if(res == 1){
                    alertToast('Poblado Actualizado', 'success');
                } else {
                    alertToast('Ha ocurrido un error al actualizar el poblado', 'error');
                }
            } )

    } ,[]);

    const handleDeletePoblado = useCallback((id) => {

        const formData = new FormData();
        formData.append('poblado_id', id);

        requestPost('eliminar_poblado', formData)
            .then( res => {
                if(res == 1){
                    alertToast('Poblado Eliminado', 'success');
                    setDataUpdate(!dataUpdate);
                } else {
                    alertToast('Ha ocurrido un error al eliminar el poblado', 'error');
                }
            } )

    },[]);

    return (
        <div className='' style={containerStyle}>

            <h3 className='m-3 mb-4 text-center'> Registrar Poblado </h3>

            <form className='d-flex flex-column row'>

                <div className={'m-auto w-75'}>
                    <SearchDataToTable name={'Buscar Poblado: '} onFilter={onFilterPoblados} />
                </div>

                <div className='d-flex justify-content-end '>
                    <button
                        className='btn btn-primary w-25 me-4'
                        onClick={ (e) => {
                            e.preventDefault();
                            addItemToTable();
                        } }
                    >
                        <HiViewGridAdd className={'fs-5 m-auto mx-1'} /> Agregar Poblado
                    </button>
                </div>

            </form>

            <div style={gridStyle} className='ag-theme-alpine row mt-3 mb-3'>
                <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombre', headerName: 'Nombre' },
                        {
                            field: 'id_estado',
                            headerName: 'Estado',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <SelectEstados
                                        name={'id_estado'}
                                        onChange={ (e) => {
                                            const { value } = e.target;
                                            data.id_estado = value;
                                            gridRef.current.api.applyTransaction({
                                                update: [{ ...data, id_estado: value }],
                                            } );
                                        } }
                                        defaultValue={data?.id_estado}
                                    />
                                );
                            }
                        },
                        {
                            field: 'id_ciudad_municipio',
                            headerName: 'Ciudad',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <SelectCiudades
                                        id_estado={data?.id_estado}
                                        defaultValue={data?.id_ciudad_municipio}
                                        name={'id_ciudad_municipio'}
                                        onChange={ (e) => {
                                            const { value } = e.target;
                                            data.id_ciudad_municipio = value;
                                            gridRef.current.api.applyTransaction({
                                                update: [{ ...data, id_ciudad_municipio: value }],
                                            });
                                        } }
                                    />
                                );
                            }
                        },
                        {
                            field: 'acciones',
                            headerName: 'Acciones',
                            editable: false,
                            cellRenderer: (params) => {
                                const { data } = params;

                                return (
                                    <div className='d-flex justify-content-evenly gap-3'>
                                        <button
                                            className={'btn w-100 '.concat(data?.id ? 'btn-secondary' : 'btn-success')}
                                            onClick={ (event) => {
                                                event.preventDefault()
                                                data?.id ? handleUpdatePoblado(data) : handleAddPoblado(data)
                                            } }
                                        >
                                            {
                                                data?.id ?
                                                    <FiEdit className={'fs-5 m-auto mx-1'} />
                                                    :
                                                    <FiSave className={'fs-5 m-auto mx-1'} />
                                            }
                                        </button>

                                        <button
                                            className='btn btn-danger w-100'
                                            onClick={ () => {
                                                alertDialog('¿Estás seguro de eliminar el poblado?', 'Eliminar', 'Cancelar')
                                                    .then((resp) => {
                                                        if (resp) {
                                                            handleDeletePoblado(data.id);
                                                        }
                                                    })
                                            } }
                                        >
                                            <BsTrash className={'fs-5 m-auto mx-1'} />
                                        </button>

                                    </div>
                                );
                            },
                        },
                    ]}
                    rowData={getPoblados || []}
                    rowSelection='single'
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    pagination={true}
                    paginationPageSize={13}
                />

            </div>

            <div className='m-3'>

            </div>

        </div>
    );
}