import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {BsTrash} from 'react-icons/bs';
import {BsFolderSymlinkFill} from 'react-icons/bs';
import {Table} from "../../Components/Tables/Table";
import {useDataCollectionRequest} from "../../Hooks";
import {AgGridReact} from "ag-grid-react";
import {SearchDataToTable} from "../../Components/SearchDataToTable";


export const ListExpedientes = () => {
    const navigate = useNavigate();

    const gridRef = useRef();

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const { dataCollectionRequest : getFiles } = useDataCollectionRequest(
        'obtener_expedientes',
        'all',
    );

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterExpediente = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    return (
      <div style={containerStyle} className="container container-md mt-5">

        <form className="row row-cols-2 mb-3">

            <div className={'col-12 col-md-8 m-auto'}>
                <SearchDataToTable name={'Buscar expediente: '} onFilter={onFilterExpediente} />
            </div>

        </form>

        <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={getFiles || []}
                columnDefs={[
                    { field: 'id_file', headerName: 'Id', hide: true, },
                    { field: 'num_expediente', headerName: 'Num. Expediente', },
                    { field: 'tipo_cliente', headerName: 'Tipo de cliente' },
                    { field: 'fecha_creacion', headerName: 'Creado', hide: true, },
                    { field: 'estado_exp', headerName: 'Estado' },
                    { field: 'nombre_cliente', headerName: 'Cliente' },
                    { field: 'materia', headerName: 'Materia' },
                    { field: 'nombre_juzgado', headerName: 'Juzgado', hide: true, },
                    {
                        field: 'button',
                        headerName: 'Ir a expediente',
                        width: 200,
                        cellRenderer: (params) => {
                            const {data} = params;
                            return (
                                <button
                                    className={'btn btn-primary m-auto'}
                                    onClick={
                                        (e) => {
                                            navigate(`/controlFile/${data.id}/${data.num_expediente}/${data.id_cliente}?q=edit-expediente`);
                                            console.log(params);
                                        }
                                    }
                                >
                                    Abrir expediente {<BsFolderSymlinkFill className={'fs-5 m-auto mx-2'} />}
                                </button>
                            );
                        },
                    }
                ]}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
            />
        </div>

      </div>
    );
}
