

export const CardNumbers = ({number = 0, title}) => {
  return (
    <div className="card mb-2 rounded-1" style={{"width": "150px", "height": "150px"}}>
        <div className="d-flex flex-column align-content-center m-auto text-center">
            <div className="m-1">
                <h2> <strong>{number}</strong> </h2>
            </div>
            <div>
                <strong className="text-muted fst-italic">{title}</strong>
            </div>
        </div>

    </div>
  )
}
