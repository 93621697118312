import React, { useCallback, useMemo, useRef, useState } from 'react';
import {BsTrash} from 'react-icons/bs';
import {Table} from "../../Components/Tables/Table";
import {useNavigate} from "react-router-dom";
import {useDataCollectionRequest} from "../../Hooks";
import { SearchDataToTable } from '../../Components/SearchDataToTable';
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';


export const ConsultationByJudgeAndClerk = () => {

    const navigate = useNavigate();

    const gridRef = useRef();

    const { dataCollectionRequest: getJudged } = useDataCollectionRequest('obtener_jueces', 'all');

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterJudgeOrClerk = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);
   
    return (
        <div style={containerStyle} className="container container-md">
            
            <h2 className="text-center m-3">Consulta por Juez y secretario</h2>

            <form className="row mb-3">

                <SearchDataToTable name={'Buscar Juez o Secretario: '} onFilter={onFilterJudgeOrClerk} />

            </form>

            <div style={gridStyle} className={'row ag-theme-alpine'}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    rowData={getJudged || []}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'nombres', headerName: 'Nombre' },
                        { field: 'apellidos', headerName: 'Apellidos' },
                        { field: 'num_celular', headerName: 'Num celular' },
                        { field: 'correo', headerName: 'Correo' },
                        { field: 'estatus', headerName: 'Estatus' },
                        { field: 'juzgado', headerName: 'Juzgado' },
                    ]}
                />
            </div>

        </div>
    );
}
