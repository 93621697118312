
export const mandatario_judicial0asesor_particular = (matter) => {

    switch (matter) {
        case 'Agrario':
            return 'Asesor Particular o Juridico';
        default:
            return 'Mandatario Judicial';
    }

}