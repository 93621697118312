import {useState, useEffect} from 'react'
import { CreateClient, ViewClients } from './components'

export const RegisterClient = () => {

    const [activeTabClient, setActiveTabClient] = useState({
        registerClient: true,
        viewClient: false
    })

    return (
        <div className='container-fluid mt-2'>
            <ul className="nav nav-tabs">
                <li 
                    className="nav-item"
                    onClick={()=>setActiveTabClient({
                        registerClient: true,
                        viewClient: false
                    })}
                >
                    <button
                        className={"m-0 btn btn-light nav-link ".concat(activeTabClient.registerClient ? "active": "")} 
                    >
                        Registrar Cliente
                    </button>
                </li>
                <li 
                    className="nav-item"
                    onClick={()=>setActiveTabClient({
                        registerClient: false,
                        viewClient: true
                    })}
                >
                    <button 
                        className={"m-0 btn btn-light nav-link ".concat(activeTabClient.viewClient ? "active": "")}  
                    >
                        Ver lista de Clientes
                    </button>
                </li>
            </ul>
            
            <div className="container container-md">

                {
                    activeTabClient.registerClient &&
                        <CreateClient />
                }

                {
                    activeTabClient.viewClient && 
                        <ViewClients />
                }
                
            </div>

        </div>
    )
}
