import React from 'react'

export const CardServices = ({imgCard, text_body}) => {
  return (
    <div className="card" style={{"width": "25rem", "height": "15rem"}}>
        <img src={imgCard} className="card-img-top w-75 h-75 m-auto" alt={text_body}/>
        <div className="card-body">
        <p className="card-text text-center">{text_body}</p>
        </div>
    </div>
  )
}
