
import {useState, useCallback, useRef, useMemo} from 'react'
import { useDataCollectionRequest } from '../../Hooks';
import { AgGridReact } from 'ag-grid-react';
import { SearchDataToTable } from '../../Components/SearchDataToTable';
import { requestPost } from '../../Helpers';
import {alertDialog, alertToast} from '../../Helpers/Alerts';

export const ViewExpedientes = () => {

    const gridRef = useRef();

    const [dataUpdate, setDataUpdate] = useState(false);

    const { dataCollectionRequest : getExpedientes, setDataCollectionRequest : setExpedientes } = useDataCollectionRequest(
        'obtener_expedientes',
        'all',
        dataUpdate
    );

    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 200px)', margin: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: '85%', width: '100%'}), []);

    const defaultColDef = useMemo(()=> {
        return {
            flex: 1,
            minWidth: 100,
            width: 150,
            resizable: true,
            editable: true,
            sortable: true,
            //floatingFilter: true,
        }
    },[])

    const onGridReady = useCallback(()=> {

    },[])

    const onFilterExpedientes = useCallback((e) => {
        gridRef.current.api.setQuickFilter(e.target.value);
    }, []);

    const handleChangeStatus = useCallback((data) => {

        const formData = new FormData();
        formData.append('expediente_id', data.id);
        formData.append('status', data.estado_exp);

        requestPost('cambiar_status_expediente', formData)
            .then((resp) => {
                if (resp > 0) {
                    alertToast('El estatus del expediente fue actualizado correctamente', 'success');
                    setDataUpdate(!dataUpdate);
                } else {
                    alertToast('No se pudo actualizar el estatus del expediente', 'error');
                }
            })
    }, [dataUpdate]);

    const handleChangeFechaTerminacion = useCallback((data) => {

        const formData = new FormData();
        formData.append('expediente_id', data.id);
        formData.append('fecha_terminacion', data.fecha_terminacion);

        alertDialog('¿Estas seguro de cambiar la fecha de terminación del expediente?', 'Si cambiar', 'Cancelar')
            .then((resp) => {
                if (resp) {
                    requestPost('cambiar_fecha_terminacion_expediente', formData)
                        .then((resp) => {
                            if (resp > 0) {
                                alertToast('La fecha de terminación del expediente fue actualizada correctamente', 'success');
                                setDataUpdate(!dataUpdate);
                            } else {
                                alertToast('No se pudo actualizar la fecha de terminación del expediente', 'error');
                            }
                        } )
                }
            } )

    } ,[]);

    return (
        <div className='' style={containerStyle}>

            <h3 className='m-3 mb-4 text-center'> Todos los expedientes </h3>

            <form className="row mb-3">
                <div className={'w-75 m-auto'}>
                    <SearchDataToTable name={'Buscar expediente: '} onFilter={onFilterExpedientes} />
                </div>
            </form>

            <div className='ag-theme-alpine row mt-3 mb-3' style={gridStyle}>
                <AgGridReact 
                    ref={gridRef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={[
                        { field: 'id', headerName: 'ID', hide: true, },
                        { field: 'num_expediente', headerName: 'Número de expediente' },
                        { field: 'nombre_cliente', headerName: 'Cliente' },
                        { field: 'tipo_cliente', headerName: 'Tipo de cliente' },
                        { field: 'fecha_creacion', headerName: 'Creado' },
                        {
                            field: 'fecha_terminacion',
                            headerName: 'Finalizado',
                            cellRenderer: (params) => {
                                const {data} = params;
                                return (
                                    <div className='d-flex justify-content-center'>
                                        <input
                                            type="date"
                                            className="form-control"
                                            data-id={data.id}
                                            data-fecha={data.fecha_terminacion}
                                            onChange={(event)=>{
                                                data.fecha_terminacion = event.target.value;
                                                console.log(data)
                                                handleChangeFechaTerminacion(data)
                                            }}
                                            defaultValue={data.fecha_terminacion}
                                        />
                                    </div>
                                )
                            }
                        },
                        { 
                            field: 'estado_exp', 
                            headerName: 'Estatus',
                            cellRenderer: (params) => {

                                const { data } = params;

                                return (
                                    <div className='d-flex justify-content-center'>
                                        <select className="form-select" aria-label="Default select example"
                                            data-id={data.id}
                                            data-status={data.estado_exp}
                                            onChange={(event)=>{
                                                data.estado_exp = event.target.value;
                                                console.log(data)
                                                handleChangeStatus(data)
                                            }}
                                            defaultValue={data.estado_exp}
                                        >
                                            <option disabled selected>Selecione una opción</option>
                                            <option value="Activo" selected={data?.estado_exp == 'Activo'}>Activo</option>
                                            <option value="Finalizado" selected={data?.estado_exp == 'Finalizado'} >Finalizado</option>
                                        </select>
                                    </div>
                                )

                            } 
                        },
                        { field: 'materia', headerName: 'Materia' },
                        { field: 'nombre_juzgado', headerName: 'Juzgado' },
                    ]}
                    rowData={getExpedientes}
                    pagination={true}
                    paginationPageSize={13}
                    rowSelection={'single'}
                    onRowDoubleClicked={(e) => {
                        console.log(e);
                    }}

                />
            </div>

            <div className=''>

            </div>

        </div>
    )
}
