

export const SelectAccion = ({ id_materia, id_tipo_juicio, onChange = () => { }, defaultValue = '', name = '', disabled = false }) => {

    return (
        <select
            className={'form-select'}
            id={name}
            name={name}
            onChange={onChange}
        >
            <option>Escoje una opción</option>
            {

            }
        </select>
    )

}