

export const juezOmagistrado = (matter) => {

    switch(matter) {
        case 'Agrario' || 'Administrativo':
            return 'Magistrado';
        default:
            return 'Juez';
    }
}