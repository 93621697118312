import {useRef} from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

export const IconToInputFile = ({ id, name, onChange, accept, multiple, classNameIcon, sizeIcon, ...rest }) => {

    const refInput = useRef(null)

    const handleUpload = () => {
        refInput.current.click();
    }



    return (
        <>
            <button className={'m-0 p-0 border-0 '.concat(classNameIcon)} onClick={handleUpload}>
                <AiOutlineCloudUpload size={sizeIcon} />
            </button>
            <input
                ref={refInput}
                style={{display: 'none'}}
                type='file'
                className='d-none'
                id={id}
                name={name}
                onChange={onChange}
                accept={accept}
                multiple={multiple}
                {...rest}
            />
        </>
    )
}