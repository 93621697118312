import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {useDataCollectionRequest, useForm} from "../../Hooks";

import logoLawyer from '../../Assets/img/Abogado_azuLOsLetra2a.png'
import {requestPost} from "../../Helpers";
import {alertError, alertSuccess} from "../../Helpers/Alerts";


export function RegisterUser() {

    const navigate = useNavigate();

    const { dataCollectionRequest: getRoles } = useDataCollectionRequest('obtener_roles', 'all');

    const {formState, onInputChange} = useForm({});


    const handleOnclickRegister = (e) => {
        e.preventDefault();

        const body = {
            nombre: formState?.name,
            apellidos: formState?.lastname,
            num_celular: formState?.numPhone,
            email: formState?.email,
            password: formState?.password,
            confirm_password: formState?.confirmPassword,
            id_rol: formState?.id_rol,
        }

        // Validate if any field is empty
        for (const element in body) {
            if (body[element] === undefined || body[element] === '' || body[element] === null) {
                console.log('No se puede enviar ningún campo vacío', element)
                alertError('Error','No se puede enviar ningún campo vacío')
                throw new Error('No se puede enviar ningún campo vacío');
            }
        }

        const formData = new FormData();
        formData.append('usuario_info', JSON.stringify(body));

        requestPost('agregar_usuario', formData)
            .then((response) => {
                console.log(response);
                if ( response > 0 ) {
                    alertSuccess('Registrado','Usuario registrado con éxito')
                } else {
                    alertError('Error','No se pudo registrar el usuario')
                }
                console.log(response.slice(1, -1));
            } )
            .catch((error) => {
                console.log(error);
            } )

    }

    
    return (
        <div className="d-flex flex-direccion-row place-content-center place-items-center m-auto">

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="fs-1 text-center"> Creación de usuarios </p>
                    </div>
                </div>

                <div className="row">
                    
                    <div className="col-12 col-md-6 align-middle">
                        <img src={logoLawyer} alt="mdo" className="col-md-12 p-5 m-auto" />
                    </div>

                    <div className="col-12 col-md-6 align-middle ">

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputName">Nombre: </label>
                            <input
                                className="form-control"
                                name="name"
                                id="inputName"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputLastName">Apellidos: </label>
                            <input
                                className="form-control "
                                id={'inputLastName'}
                                name="lastname"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputEmail"> Correo: </label>
                            <input
                                className="form-control "
                                id={'inputEmail'}
                                name="email"
                                type="email"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputPhone"> Num de celular: </label>
                            <input
                                className="form-control "
                                id={'inputPhone'}
                                name="numPhone"
                                type="text"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputTypeUser"> Rol del usuario: </label>
                            <select
                                className={'form-select'}
                                name={'id_rol'}
                                onChange={onInputChange}
                                required
                            >
                                <option value={''}> Seleccione un rol </option>
                                {
                                    getRoles?.map((role) => (
                                        <option
                                            key={role.id}
                                            value={role.id}
                                        >
                                            {role.nombre}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputPassword"> Contraseña </label>
                            <input
                                className="form-control "
                                id={'inputPassword'}
                                name="password"
                                type="password"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="inputPassword"> Confirmar contraseña </label>
                            <input
                                className="form-control "
                                id={'inputPassword'}
                                name="confirmPassword"
                                type="password"
                                onChange={onInputChange}
                                required
                            />
                        </div>

                        <div className="row">
                            <div className="d-flex justify-content-end ">
                                <button
                                    type="button"
                                    className="btn btn-primary w-25 mb-1 "
                                    onClick={handleOnclickRegister}
                                >
                                    Register
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}