import { useDataCollectionRequest } from "../../../Hooks";
import { getYearsFrom } from "./helpers/getYearFrom";


export const NumExpediente = ({ onInputChange, minLengthDigit, maxLengthDigit, matterDisable = false, matterDefaultValue = '', digitDefaultValue = '', yearDefaultValue = '' }) => {

    const { dataCollectionRequest: getMaterias } = useDataCollectionRequest('obtener_materias', 'all');

    return (
        <>
            <div className='row row-cols-4'>

                <div className='mb-3 col-12 col-md-4'>

                    <div className="form-floating">
                        <select className="form-select" id="floatingSelect"
                                aria-label="Floating label select example"
                                onChange={ (e) => {
                                    onInputChange(e);
                                } }
                                name='matterLetter'
                                required
                                disabled={matterDisable}
                                defaultValue={matterDefaultValue}
                        >
                            <option selected>Escoge la materia</option>
                            {
                                getMaterias &&
                                getMaterias.map((matter, index) => {

                                    return (
                                        <option
                                            selected = {matterDefaultValue == matter?.tipo_juicio}
                                            key={index}
                                            value={ JSON.stringify(matter) }
                                        >
                                            {matter.tipo_juicio}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="floatingSelect">Elije la materia</label>
                    </div>

                </div>

                <div className={'mb-3 col-12 col-md-4'}>
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" id="floatingInput"
                            onChange={onInputChange}
                            name={'numberToFile'}
                            minLength={minLengthDigit}
                            maxLength={maxLengthDigit}
                            required
                            defaultValue={digitDefaultValue}
                        />
                        <label htmlFor="floatingInput">Escribe 4 dígitos</label>
                    </div>
                </div>

                <div className={'mb-3 col-12 col-md-4'}>
                    <div className="form-floating mb-3">
                        <select className="form-select" id="floatingSelect"
                                aria-label="Floating label select example"
                                onChange={onInputChange}
                                name='yearToFile'
                                required
                                defaultValue={yearDefaultValue}
                        >
                            <option selected>Selecciona un año</option>
                            {
                                getYearsFrom(1998).map((year, index) => {
                                    return (
                                        <option
                                            selected={yearDefaultValue == year}
                                            key={index}
                                            value={year}
                                        >
                                            {year}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="floatingInput">Año</label>
                    </div>
                </div>


            </div>
            
        </>
    )
}